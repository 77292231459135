import React from "react";
import { useNavigate } from "react-router-dom";
import notFoundDealsImg from "../../../assets/images/v1/deals/not-found-deals.svg";
import Footer from "../../sharedComponent/Footer";

const ErrorPage = () => {
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate("/");
  };

  return (
    <div>
      <div
        aria-labelledby="pageTitle"
        className="flex flex-col items-center justify-center h-screen bg-white text-gray-900"
      >
        <img
          src={notFoundDealsImg}
          className="w-[250px] h-[250px]"
          alt="No deals found"
        />
        <div className="p-4 space-y-4">
          <div className="flex phone:flex-col tablet:flex-row space-y-3 space-x-4 items-center">
            <div className="font-semibold text-red-500 text-[160px]">404</div>
            <div className="space-y-3">
              <h1 id="pageTitle" className="flex items-center phone:justify-center tablet:justify-start space-x-2">
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-red-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
                <span className="text-xl font-medium text-black sm:text-2xl">
                  Oops! Page not found.
                </span>
              </h1>
              <div className="text-base font-normal phone:text-center tablet:text-start text-black">
                The page you are looking for was not found.
              </div>
              <div className="text-base font-normal phone:text-center tablet:text-start text-black">
                You may return to home page
              </div>
              <button
                className="px-16 py-3 rounded-lg bg-[#E66927] text-base text-white font-medium flex items-center gap-2 justify-center phone:w-full "
                onClick={goToHomePage}
              >
                Go to home page
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ErrorPage;
