import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GETUSERBYID,
  UPDATEUSERBYID,
  UPDATEUSERNAME,
  UPDATEUSERPASSWORD,
} from "../api";

import logout from "../../_helperfunctions/Logout";
export const getUser = createAsyncThunk(
  "/getuserbyid",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GETUSERBYID}?userId=${data.userId}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      return response.data;
    } catch (error) {
      // If the error has a response (server-side error)
      if (error.response) {
        return rejectWithValue(error.response.data);
      }
      // If the error does not have a response (network error, etc.)
      return rejectWithValue(error.message);
    }
  }
);

export const UpdateUser = createAsyncThunk(
  "users/updateUserById", // Use a more descriptive action type
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATEUSERBYID, data.newdatavalues, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      return response.data; // Return the data on success
    } catch (error) {
      // Handle the error and return a rejected value
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Something went wrong";
      return rejectWithValue(errorMessage); // Pass the error message to the action payload
    }
  }
);

export const UpdateUserName = createAsyncThunk(
  "/updateusername",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATEUSERNAME, data, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      return response.data;
    } catch (error) {
      // Return the error response if available, or a default message
      return rejectWithValue(
        error.response ? error.response.data : "An error occurred"
      );
    }
  }
);

export const UpdateUserPassword = createAsyncThunk(
  "/updateuserpassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATEUSERPASSWORD, data, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      return response.data;
    } catch (error) {
      // Return the error response if available, or a default message
      return rejectWithValue(error.response && error.response.data);
    }
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.data = action.payload.data || []; // Use a fallback if data is null/undefined
        state.isLoading = false;
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
        state.error = action.payload;
      })
      .addCase(UpdateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data; // Update state with new user data
      })
      .addCase(UpdateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      })
      .addCase(UpdateUserName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data; // Update state with new user data
      })
      .addCase(UpdateUserName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateUserName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      })
      .addCase(UpdateUserPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data; // Update state with new user data
      })
      .addCase(UpdateUserPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateUserPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      });
  },
});
export default userSlice.reducer;
