import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ADD_DEALS, UPDATE_DEALS } from "../api";
import logout from "../../_helperfunctions/Logout";
export const AddDeals = createAsyncThunk(
  "/addeals",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(ADD_DEALS, data.formData, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log("AddDeals", response.data);
      return response.data;
    } catch (error) {
      // console.error("Error updating adddeals user:", error);
      // If the error is a response from the server (with status code)

      if (error.response) {
        // Return the error response from the server
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        // Request was made but no response was received
        // console.log("error.request", error.request);
        return rejectWithValue("No response from server");
      } else {
        // Something else caused the error
        // console.log("error.message", error.message);
        return rejectWithValue("Error: " + error.message);
      }
    }
  }
);
export const UpdateDeals = createAsyncThunk(
  "/updatedeals",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(UPDATE_DEALS, data.formData, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log("AddDeals", response.data);
      return response.data;
    } catch (error) {
      // console.error("Error updating adddeals user:", error);
      // If the error is a response from the server (with status code)

      if (error.response) {
        // Return the error response from the server
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        // Request was made but no response was received
        // console.log("error.request", error.request);
        return rejectWithValue("No response from server");
      } else {
        // Something else caused the error
        // console.log("error.message", error.message);
        return rejectWithValue("Error: " + error.message);
      }
    }
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const addealsSlice = createSlice({
  name: "addeals",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(AddDeals.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(AddDeals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AddDeals.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      })
      .addCase(UpdateDeals.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(UpdateDeals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateDeals.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});
export default addealsSlice.reducer;
