import { useEffect } from "react";
import flashsale from "../../../../assets/images/v1/homepage/flashsale-desktop.png";
import discount from "../../../../assets/images/v1/homepage/discount.png";
import flashtablet from "../../../../assets/images/v1/homepage/flash-tablet.png";
import discounttablet from "../../../../assets/images/v1/homepage/discount-tablet.png";
import { useDispatch, useSelector } from "react-redux";
import { getFlashsale } from "../../../../store/slices/flashsaleSlice";
import MobileHeaderDown from "../../MobileHeaderMenu/MobileHeaderDown";
import { timeRemainingFromNow } from "../../constant";
import CountdownTimer from "./CountdownTimer";
import wrapperImage from "../../../../assets/images/v1/homepage/wrapper.svg";
import { Link } from "react-router-dom";
import { getProductingleImageBanner } from "../../ImageMaster/ImageMaster";
const TopNav = ({ isPremium }) => {
  const customStyle = {
    backgroundImage: `url(${wrapperImage})`,
    backgroundSize: "cover",
    width: "100%",
    backgroundPosition: "right 25% top",
  };

  const dispatch = useDispatch();
  const homeFlashsale = useSelector((state) => state.home_flashsale.data);

  let timerObject = (homeFlashsale &&
    homeFlashsale.fldSaleEndDate &&
    timeRemainingFromNow(homeFlashsale.fldSaleEndDate)) || {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  useEffect(() => {
    dispatch(getFlashsale());
  }, [dispatch]);

  useEffect(() => {
    timerObject = (homeFlashsale &&
      homeFlashsale.fldSaleEndDate &&
      timeRemainingFromNow(homeFlashsale.fldSaleEndDate)) || {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }, [homeFlashsale]);
  // Generate dynamic image URLs
  const discountImageSrc = getProductingleImageBanner(
    homeFlashsale?.fldSaleDiscountImage
  );
  return homeFlashsale && homeFlashsale.fldSaleStatus === "Y" ? (
    <div>
      <div className="block w-full phone:hidden" style={customStyle}>
        <div className="w-full phone:hidden px-[56px] py-[14px] tablet:py-[16px] flex items-center max-w-[1440px] mx-auto">
          <div className="block tablet:hidden phone:hidden">
            <img
              src={flashsale}
              className="w-[235px] h-[40px]"
              alt="flashsale"
            />
          </div>
          <div className="hidden">
            <img
              src={flashtablet}
              className="w-[105px] h-[48px]"
              alt="flashsale"
            />
          </div>
          <div className="w-[466px] h-[52px] tablet:w-[121px] tablet:h-[52px] ml-[32px] tablet:ml-[0]">
            <div className="block tablet:hidden">
              <img src={discountImageSrc} className="w-full" alt="discount" />
            </div>
            <div className="hidden tablet:block">
              <img src={discountImageSrc} className="w-full" alt="discount" />
            </div>
          </div>
          {/* timer */}
          <CountdownTimer
            initialDays={timerObject.days}
            initialHours={timerObject.hours}
            initialMinutes={timerObject.minutes}
            initialSeconds={timerObject.seconds}
          />
          {/* timer end */}
          <div className="bg-white/20 w-[1px] h-[36px] mx-5" />
          <div className="flex flex-col items-center gap-1 mr-[32px] packages-banner-wrapper">
            <div className="flex gap-[2px]">
              <div>
                <p
                  className="text-white text-[32px] packages-value font-bold"
                  data-time-block={0}
                >
                  {homeFlashsale?.fldSaleRemainingPackage || 0}
                </p>
              </div>
            </div>
            <p className="text-[8px] leading-[14px] text-white whitespace-nowrap">
              REMAINING PACKAGES
            </p>
          </div>
          {!isPremium && (
            <Link
              to="/subscribe"
              className="w-fit no-underline rounded-[8px] border-none bg-[#E66927] hover:bg-[#f98344] transition-all duration-200 py-[8px] px-[24px] text-white font-semibold text-[16px] leading-[24px] ml-auto whitespace-nowrap"
            >
              Join Now
            </Link>
          )}
          <MobileHeaderDown data={homeFlashsale} />
        </div>
      </div>
      {/* Mobile Device */}
    </div>
  ) : (
    <></>
  );
};

export default TopNav;
