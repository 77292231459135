import { useEffect, useState } from "react";
import AllCategories from "../../AllCategories/index";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { routes } from "../../../../routes/route";
import menu from "../../../../assets/images/v1/icons/menu.svg";
import { useSearchParams } from "react-router-dom";
const NavComponent = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("business_type");
  const tabs = [
    { label: "Deals", route: "/deals/main" },
    { label: "Wholesale Suppliers", route: "/suppliers/main" },

    {
      label: "Liquidators",
      route: "/suppliers/main?business_type=fldSupplierTypeLiquidator",
    },
    {
      label: "Dropshippers",
      route: "/suppliers/main?business_type=dropshipper",
    },
  ];

  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();
  const handleTabClick = (tab) => {
    if (tab.label === "Wholesale Suppliers" || tab.label === "Deals") {
      const firstRoute = getFirstRoute(tab.route);
      const ffR = getFirstRoute(location.pathname);
      const lastRoute = getLastRoute(location.pathname) || "main";
      if (ffR == "user" || ffR == "supplier" || ffR == "deal") {
        navigate(`/${firstRoute}/main`);
      } else {
        navigate(`/${firstRoute}/${lastRoute}`);
      }
    } else if (tab.label === "Liquidators") {
      const firstRoute = getFirstRoute(tab.route);
      const lastRoute = getLastRoute(location.pathname) || "main";
      const ffR = getFirstRoute(location.pathname);
      if (ffR == "user" || ffR == "supplier" || ffR == "deal") {
        navigate(`/${firstRoute}/main?business_type=fldSupplierTypeLiquidator`);
      } else {
        navigate(
          `/${firstRoute}/${lastRoute}?business_type=fldSupplierTypeLiquidator`
        );
      }
    } else if (tab.label === "Dropshippers") {
      const firstRoute = getFirstRoute(tab.route);
      const lastRoute = getLastRoute(location.pathname) || "main";
      const ffR = getFirstRoute(location.pathname);
      if (ffR == "user" || ffR == "supplier" || ffR == "deal") {
        navigate(`/${firstRoute}/main?business_type=dropshipper`);
      } else {
        navigate(`/${firstRoute}/${lastRoute}?business_type=dropshipper`);
      }
    }
    setActiveTab(tab.label);
  };

  const getFirstRoute = (data) => {
    const datas = data.split("/");
    return datas[1];
  };

  const getLastRoute = (data) => {
    const datas = data.split("/");
    return datas[2];
  };

  useEffect(() => {
    const path = location.pathname;
    const typeArray = type ? type.split(",") : []; // Split type into an array if it exists
    if (
      getSecondRoute(location.pathname) == `${routes.deals}` ||
      getSecondRoute(location.pathname) == "deal"
    ) {
      setActiveTab("Deals");
    } else if (
      getSecondRoute(location.pathname) == "suppliers" ||
      (getSecondRoute(location.pathname) == "supplier" &&
        path !== "/" &&
        (type == null || type === ""))
    ) {
      setActiveTab("Wholesale Suppliers");
    } else if (
      typeArray.includes("dropshipper") &&
      getSecondRoute(location.pathname) == "suppliers"
    ) {
      setActiveTab("Dropshippers");
      return;
    } else if (
      typeArray.includes("fldSupplierTypeLiquidator") &&
      getSecondRoute(location.pathname) == "suppliers"
    ) {
      setActiveTab("Liquidators");
      return;
    } else {
      setActiveTab("");
    }
  }, [type, location.pathname]);
  const getSecondRoute = (data) => {
    const datas = data.split("/");
    return datas[1];
  };

  return (
    <nav className="w-full bg-white border-y border-neutral-200 test-AllCategoriesNav">
      <div className="relative w-full hide-scrollbar max-w-[1440px] mx-auto phone:overflow-x-scroll phone:px-[24px] px-[56px] flex items-center">
        <div className="relative phone:hidden tablet:hidden">
          <AllCategories />
          {/* Side Navigation Desktop */}
        </div>
        <div className="flex items-center w-[800px]">
          <button
            id="toggle-button-list-category-tablet"
            className="hidden tablet:block"
          >
            <img src={menu} alt="menu" />
          </button>
          {tabs.map((tab) => (
            <button
              key={tab.label}
              className={`py-[12px] min-w-fit px-[24px] text-[14px] leading-[21px] font-normal border-b-4 ${
                activeTab === tab.label
                  ? "text-primary-main border-primary-main"
                  : "text-neutral-500 border-b-transparent"
              } hover:border-primary-main`}
              onClick={() => handleTabClick(tab)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default NavComponent;
