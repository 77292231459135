// src/store/slices/sourcingSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_COUSTOMSOURCING } from "../api";
import logout from "../../_helperfunctions/Logout"; // Ensure you have a logout utility

// Thunk for fetching custom sourcing with pagination
export const fetchCustomSourcing = createAsyncThunk(
  "sourcing/fetchCustomSourcing",
  async ({ token, filters, page = 1 }, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_COUSTOMSOURCING, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { ...filters, page }, // Pass filters and page as query params
      });
      return response.data.data; // Returns { data: [...], pagination: {...} }
    } catch (error) {
      // Handle errors appropriately
      if (error.response && error.response.status === 403) {
        logout(); // Log out if token is invalid
      }
      return rejectWithValue(
        error.response?.data || { message: "Something went wrong while fetching sourcing reports." }
      );
    }
  }
);

// Initial state with pagination
const initialState = {
  sourcingReports: [],
  loading: false,
  error: null,
  filters: {
    search: "",
    averageOrderValue: "",
    country: "",
    specialRequirements: "",
  },
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    limit: 10, // Items per page
  },
};

// Create the slice
const sourcingSlice = createSlice({
  name: "sourcing",
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
      state.pagination.currentPage = 1; // Reset to first page on filter change
    },
    clearFilters(state) {
      state.filters = initialState.filters;
      state.pagination.currentPage = 1;
    },
    setCurrentPage(state, action) {
      state.pagination.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomSourcing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomSourcing.fulfilled, (state, action) => {
        state.loading = false;
        state.sourcingReports = action.payload.data;
        state.pagination = {
          currentPage: action.payload.pagination.currentPage,
          totalPages: action.payload.pagination.totalPages,
          totalItems: action.payload.pagination.totalItems,
          limit: action.payload.pagination.limit,
        };
      })
      .addCase(fetchCustomSourcing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { setFilters, clearFilters, setCurrentPage } = sourcingSlice.actions;
export default sourcingSlice.reducer;
