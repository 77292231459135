import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { CONTACT_US } from '../api';

export const sendContactQuery = createAsyncThunk(
  'contact/sendContactQuery',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(CONTACT_US, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error occurred');
    }
  }
);

const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    isLoading: false,
    autoAnswers: null,
    contactQuerySent: false,
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.autoAnswers = null;
      state.contactQuerySent = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendContactQuery.pending, (state) => {
        state.isLoading = true;
        state.autoAnswers = null;
        state.contactQuerySent = false;
        state.error = null;
      })
      .addCase(sendContactQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.contactQuerySent = true;
        state.autoAnswers = {
          autoAnswerSuggestions: action.payload?.data?.autoAnswerSuggestions
            ? action.payload.data.autoAnswerSuggestions.map((item) => ({
                fldHeadingMessage: item.fldHeadingMessage,
                fldTextMessage: item.fldTextMessage,
              }))
            : [], // Default to an empty array if no data
        };
      })
      
      
      
      
      .addCase(sendContactQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetState } = contactSlice.actions;
export default contactSlice.reducer;