import React from "react";
import arrowrightup from "../../../assets/images/v1/icons/arrow-up-right-orange.svg";
import tour from "../../../assets/images/v1/icons/tour.png";
import stories from "../../../assets/images/v1/icons/stories.png";
import join from "../../../assets/images/v1/icons/join.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {useRedirection} from "../../../hooks"

const DetailFooter = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <div>
      <div className="w-full max-w-[1440px] mx-auto phone:py-[72px] phone:px-[24px] phone:grid-cols-1 py-[80px] tablet:px-[137px] px-[120px] grid tablet:grid-cols-1 grid-cols-3 gap-[32px]">
        <div className="w-full">
          <img src={tour} className="w-[72px] h-[72px]" alt="" suitcase="" />
          <h3 className="font-bold text-[24px] leading-[33.6px] text-white mt-[24px]">
            Take a tour
          </h3>
          <p className="text-[16px] leading-[24px] text-white font-medium my-[12px]">
            A better way to find wholesalers, dropshippers and wholesale
            products
          </p>
          <p className="text-[16px] leading-[24px] font-normal text-white text-opacity-[0.56]">
            Whether you’re successful retailer or a new starter, WhosaleDeals
            can make the difference. Would you like to get better products at
            lower prices? How about knowing how much you can earn at a glance,
            saving . your time and bad suprise?
          </p>
          <Link
            to="/tour-step-1"
            className="flex items-center gap-[6px] text-[16px] leading-[24px] font-medium text-[#E66927] mt-[24px]"
          >
            Take a tour
            <img
              src={arrowrightup}
              className="w-[20px] h-[20px]"
              alt="arrow-up-right"
            />
          </Link>
        </div>
        <div className="w-full">
          <img src={stories} className="w-[72px] h-[72px]" alt="conversation" />
          <h3 className="font-bold text-[24px] leading-[33.6px] text-white mt-[24px]">
            Our success stories
          </h3>
          <p className="text-[16px] leading-[24px] text-white font-medium my-[12px]">
            See how Wholesale Deals has helped other traders like you succeed.
          </p>
          <p className="text-[16px] leading-[24px] font-normal text-white text-opacity-[0.56]">
            Since our launch in 2004 we’ve helped thousands of traders,
            companies and individuals find quality wholesalers and products. Our
            new deal tracker service and other improvements are the results of
            your feedback. Read our{" "}
            <Link to="/all-testimonials" className="text-[#E66927]">
              testimonial page
            </Link>{" "}
            and, if your are a member,{" "}
            <Link to="/contact-us" className="text-[#E66927]">
              drop us a line.
            </Link>
          </p>
          <Link
            to="/all-testimonials"
            className="flex items-center gap-[6px] text-[16px] leading-[24px] font-medium text-[#E66927] mt-[24px]"
          >
            View testimonials
            <img
              src={arrowrightup}
              className="w-[20px] h-[20px]"
              alt="arrow-up-right"
            />
          </Link>
        </div>
        <div className="w-full">
          <img src={join} className="w-[72px] h-[72px]" alt="notes" />
          <h3 className="font-bold text-[24px] leading-[33.6px] text-white mt-[24px]">
            Join with us
          </h3>
          <p className="text-[16px] leading-[24px] text-white font-medium my-[12px]">
            Ready to become a member or just coming back?
          </p>
          <p className="text-[16px] leading-[24px] font-normal text-white text-opacity-[0.56]">
            Save money with our new membership offers.
          </p>
          <Link
            to="/subscribe"
            className="text-[16px] leading-[24px] font-normal text-white text-opacity-[0.56] mt-[12px]"
          >
            <span className="text-[#E66927]">Upgrade to premium</span>, for full
            access and unlimited custom sourcing support
          </Link>
          {!isAuthenticated && (
            <Link
              to="/login?tab=register"
              className="flex items-center gap-[6px] text-[16px] leading-[24px] font-medium text-[#E66927] mt-[24px]"
            >
              Register now
              <img
                src={arrowrightup}
                className="w-[20px] h-[20px]"
                alt="arrow-up-right"
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailFooter;
