import React from "react";

const DealsSearchHeading = ({
  dealsCount,
  keyword,
  selectedCountries,
  categoryName, // new prop for category name
}) => {
  const lastKeyword = Array.isArray(keyword)
    ? keyword[keyword.length - 1]
    : keyword;

  // Decode the categoryName so that &amp; becomes &
  const decodedCategoryName = categoryName ? decodeHtml(categoryName) : "";

  const countryNames = selectedCountries?.length
    ? selectedCountries.join(", ")
    : "";

  return (
    <h1 className="font-bold my-[24px] text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] text-[#000]">
      {dealsCount} Live Wholesaler Deals for keyword{" "}
      <span className="text-secondary-main">“{lastKeyword}”</span>
      {decodedCategoryName && (
        <>
          {" under category "}
          <span className="text-secondary-main">{decodedCategoryName}</span>
        </>
      )}
      {countryNames && (
        <>
          {" from the "}
          <span className="text-secondary-main">{countryNames}</span>
        </>
      )}
    </h1>
  );
};

// Helper function can be defined in this file or imported from a util file
const decodeHtml = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

export default DealsSearchHeading;
