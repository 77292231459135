

export const routes = {
  dealsSlug: "deals",
  deals: "deals",
  tourStep1: "/tour-step-1",
  tourStep2: "/tour-step-2",
  tourStep3: "/tour-step-3",
  tourStep4: "/tour-step-4",
  resetpassword: "/reset-password",
  userlogin: "/login",
  alltestimonials: "/all-testimonials",
  subscribe: "/subscribe",
  thankYou: "/thank-you",
  subscribeSuccess: "/subscribe-success",
  single_deals: "deal/:slug",
  single_deals_root: "/deal/",
  single_supplier: "/supplier/:slug",
  single_supplier_root: "/supplier/",
  supplier_category: "supplier-category",
  supplier_category_slug: "suppliers",
  DealsBasket: "/user/my-deals-basket",
  alphabetic_search: "alphabetical-keyword-index",
  contact:"/contact-us",

  Cookies:"/cookies",
};

export const routesforAuth = [
  {
    dealsSlug: "/deals",
    deals: "/deals",
    tourStep1: "/tour-step-1",
    tourStep2: "/tour-step-2",
    tourStep3: "/tour-step-3",
    tourStep4: "/tour-step-4",
    resetpassword: "/reset-password",
    userlogin: "/login",
    alltestimonials: "/all-testimonials",
    subscribe: "/subscribe",
    thankYou: "/thank-you",
    DealsBasketEmpty: "/my-deals-basket-empty",
    DealsBasketNoLogin: "my-deals-basket-no-login",
    DealsBasketFilled: "my-deals-basket-filled",
    single_deals: "deal/:slug",
    single_supplier: "/supplier/:slug",
    supplier_category: "supplier-category",
    supplier_category_slug: "suppliers",
    alphabetic_search: "alphabetical-keyword-index",
    contact:"/contact-us",
  
    PrivacyPolicy:"/privacy-policy",
    TermAndUse:"/terms-and-conditions",
    Cookies:"/cookies",
  },
];
