import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_PACKAGE} from "../api";
import logout from "../../_helperfunctions/Logout";
export const getPackage = createAsyncThunk(
  "/getPackage",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_PACKAGE}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      // Return a custom error message using rejectWithValue
      return rejectWithValue(
        error.response?.data 
      );
    }
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const packageSlice = createSlice({
  name: "package",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPackage.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getPackage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPackage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      })
  },
});
export default packageSlice.reducer;
