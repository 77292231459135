// src/store/slices/otoApplicableSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { OTO_APPLICABLE } from "../api";
import logout from "../../_helperfunctions/Logout";

// Async thunk for checking OTO applicability
export const checkOtoApplicable = createAsyncThunk(
  "otoApplicable/checkOtoApplicable",
  async ({ token, transactionId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        OTO_APPLICABLE,
        { transactionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API Error:", error); 
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: error.message || "An error occurred" });
      }
    }
  }
);

const otoApplicableSlice = createSlice({
  name: "otoApplicable",
  initialState: {
    isLoading: false,
    isDiscountApplied: false,
    yearlyPrice: 0,
    totalAmountToPaid: 0,
    alreadyPaidAmount: 0,
    discountPercentage: "0.00",
    error: null,
  },
  reducers: {
    resetOtoApplicableState: (state) => {
      state.isLoading = false;
      state.isDiscountApplied = false;
      state.yearlyPrice = 0;
      state.totalAmountToPaid = 0;
      state.alreadyPaidAmount = 0;
      state.discountPercentage = "0.00";
      state.error = null;
    },
    setIsDiscountApplied: (state, action) => {
      state.isDiscountApplied = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkOtoApplicable.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(checkOtoApplicable.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          const {
            isDiscountApplied,
            yearlyPrice,
            totalAmountToPaid,
            alreadyPaidAmount,
            discountPercentage,
          } = action.payload.data;
          state.isDiscountApplied = isDiscountApplied;
          state.yearlyPrice = yearlyPrice;
          state.totalAmountToPaid = totalAmountToPaid;
          state.alreadyPaidAmount = alreadyPaidAmount;
          state.discountPercentage = discountPercentage;
        } else {
          state.error = action.payload.message || "Failed to fetch discount information.";
        }
      })
      .addCase(checkOtoApplicable.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload && action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
        state.error = action.payload.message || "An error occurred while checking OTO applicability.";
      });
  },
});

export const { resetOtoApplicableState } = otoApplicableSlice.actions;

export default otoApplicableSlice.reducer;
