import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_ALL_TESTIMONAILS } from "../api";
export const getAllTestiMonials = createAsyncThunk(
  "/getAllTestiMonials",
  async () => {
    const response = await axios.get(GET_ALL_TESTIMONAILS);
    return response.data;
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const alltestimonialsSlice = createSlice({
  name: "alltestimonials",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllTestiMonials.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAllTestiMonials.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTestiMonials.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload;
      });
  },
});
export default alltestimonialsSlice.reducer;
