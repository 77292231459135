import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_SINGLE_DEALS } from "../api";

export const getSingleDeal = createAsyncThunk(
  "/getSingleDeal",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_SINGLE_DEALS}/${data.slug}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data ||
          "Something went wrong while fetching the deal profile."
      );
    }
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};

const singleDealSlice = createSlice({
  name: "single_deals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSingleDeal.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSingleDeal.fulfilled, (state, action) => {
        // Handle the case when the request is successful
        state.isLoading = false;
        state.data = action.payload.data; // Store the fetched data
      })
      .addCase(getSingleDeal.rejected, (state, action) => {
        // Handle the case when the request fails
        state.isLoading = false;
        state.error = action.payload || "Failed to load deals.";
      });
  },
});

export default singleDealSlice.reducer;
