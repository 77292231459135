import React from "react";
import notFoundDealsImg from "../../../../../assets/images/v1/deals/not-found-deals.svg"; // Update the path based on your file structure
// ../assets/images/v1/deals/not-found-deals.svg
const NoDealsFound = ({
  keyword,
  removeSearchAndSearchModeFromURL,
  searchInputRef,
  resetFilters,
}) => {
  const focusSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
      removeSearchAndSearchModeFromURL();
      resetFilters(); // Focus on the input field
    }
  };

  return (
    <div className="w-full py-[32px] px-[40px] flex phone:flex-col gap-[32px] phone:p-[24px] phone:gap-[16px] bg-white border border-neutral-200 rounded-[12px] bg-white">
      {/* Image */}
      <img
        src={notFoundDealsImg}
        className="w-[140px] h-[140px] phone:w-[84px] phone:h-[84px] tablet:min-w-[140px] desktop:min-w-[140px]"
        alt="No deals found"
      />

      {/* Content */}
      <div className="grid gap-[24px] phone:gap-[16px]">
        <div className="grid gap-[12px]">
          <h1 className="text-[20px] leading-[27px] text-[#000] font-semibold phone:text-[18px] phone:leading-[27px]">
            Oops, There are no deals were found for keywords
            <span className="text-secondary-main">“{keyword}”</span> in the
            category
          </h1>
          <p className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] text-neutral-500 font-normal">
            Try using fewer filters or change keyword
          </p>
        </div>

        {/* Change Keyword Button */}
        <button
          className="w-fit text-white px-[32px] py-[9px] rounded-[8px] bg-primary-main hover:bg-primary-hover font-semibold text-[14px] leading-[21px] transition-all duration-300"
          onClick={focusSearchInput}
        >
          Change Keyword
        </button>
      </div>
    </div>
  );
};

export default NoDealsFound;
