import { useState, useEffect } from "react";
import ShowFlag from "../../../../sharedComponent/Flag/ShowFlag";
import orangex from "../../../../../assets/images/v1/deals/orangex.svg";
import down from "../../../../../assets/images/v1/deals/down.svg";
import trash from "../../../../../assets/images/v1/deals/trash.svg";

const CountryFilterDropdown = ({
  countries,
  selectedCountries,
  setSelectedCountries,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleCountries, setVisibleCountries] = useState(7);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const showMoreCountries = () => {
    setVisibleCountries((prevCount) => prevCount + 5);
  };

  const handleCountrySelection = (countryId) => {
    setSelectedCountries((prevSelected) => {
      if (prevSelected.includes(countryId)) {
        return prevSelected.filter((id) => id !== countryId);
      } else {
        return [...prevSelected, countryId];
      }
    });
    setSearchTerm("");
  };

  const handleRemoveCountry = (countryId) => {
    setSelectedCountries((prevSelected) =>
      prevSelected.filter((id) => id !== countryId)
    );
    setSearchTerm(""); 
  };

  const resetFilters = () => {
    setSelectedCountries([]);
    setSearchTerm(""); 
  };

  const filteredCountries = countries.filter((country) =>
    country.fldCountryName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedCountries = [...filteredCountries].sort((a, b) => {
    const aSelected = selectedCountries.includes(a.fldCountryId) ? 0 : 1;
    const bSelected = selectedCountries.includes(b.fldCountryId) ? 0 : 1;
    if (aSelected !== bSelected) {
      return aSelected - bSelected;
    }
    return a.fldCountryName.localeCompare(b.fldCountryName);
  });

  const countriesToDisplay = sortedCountries.slice(0, visibleCountries);

  const selectedCountryObjects = countries.filter((country) =>
    selectedCountries.includes(country.fldCountryId)
  );

  return (
    <div className="filter-dropdown w-full py-[8px] px-[20px] border-t border-neutral-200">
      <button
        className="w-full py-[16px] flex items-center justify-between"
        onClick={toggleDropdown}
      >
        <h1 className="font-bold text-[16px] leading-[24px] text-left text-neutral-900">
          All Countries
        </h1>
        <img
          data-icon="arrow"
          src={down}
          alt="Toggle Dropdown"
          className={`w-[24px] h-[24px] transition-transform duration-300 ${
            isOpen ? "" : "rotate-180"
          }`}
        />
      </button>

      {isOpen && (
        <div className="w-full filter-dropdown-container mt-[8px]">
          {/* Selected Countries Display */}
          <div className="w-full my-3 gap-2 flex flex-row items-center flex-wrap">
            {selectedCountryObjects.map((country) => (
              <button
                key={country.fldCountryId}
                className="pl-4 pr-2 py-[8px] flex items-center gap-[4px] bg-[#FAE1D4] rounded-[8px] text-[14px] leading-[21px] text-primary-main hover:bg-primary-surface/[0.75] hover:text-primary-hover"
              >
                <ShowFlag
                  countryName={country.fldCountryName}
                  countryList={countries}
                />
                <span className="ml-1">{country.fldCountryName}</span>
                <div className="w-[1px] h-[16px] bg-primary-main opacity-60 ml-1 mt-[2px]" />
                <img
                  src={orangex}
                  className="w-[18px] h-[18px] mt-[2px] cursor-pointer"
                  alt={`Remove ${country.fldCountryName}`}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering other click events
                    handleRemoveCountry(country.fldCountryId);
                  }}
                />
              </button>
            ))}

            {/* Reset Filters Button */}
            {selectedCountries.length > 0 && (
              <button
                className="flex gap-2 items-center ml-1 py-[6px] px-2 text-primary-main hover:bg-primary-surface/[0.75] hover:text-primary-hover bg-[#FAE1D4] rounded-[8px]"
                onClick={resetFilters}
                title="Reset Filters"
              >
                Clear All
                <img
                  src={trash}
                  className="w-[18px] h-[18px] text-secondary-main"
                  alt="Reset Filters"
                />
              </button>
            )}
          </div>

          {/* Search Input */}
          <div className="search-countries">
            <input
              type="text"
              className="w-full py-[12px] px-[16px] rounded-[10px] min-h-[48px] mb-[8px] border border-neutral-200 text-[16px] leading-[24px] font-normal text-neutral-400 focus:outline-[#E66927]"
              placeholder="Search Countries"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Countries List */}
          {countriesToDisplay.length > 0 ? (
            sortedCountries.slice(0, visibleCountries).map((country) => (
              <label
                key={country.fldCountryId}
                className="cursor-pointer w-full flex items-center gap-[12px] py-[12px]"
              >
                <input
                  type="checkbox"
                  className="w-[20px] accent-primary-main h-[20px] cursor-pointer"
                  checked={selectedCountries.includes(country.fldCountryId)}
                  onChange={() => handleCountrySelection(country.fldCountryId)}
                />
                <div className="flex items-center gap-[8px]">
                  <ShowFlag
                    countryName={country.fldCountryName}
                    countryList={countries}
                  />
                  <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                    {country.fldCountryName}
                  </p>
                </div>
              </label>
            ))
          ) : (
            <span className="text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
              Not found
            </span>
          )}

          {/* Show More Button */}
          {sortedCountries.length > visibleCountries && (
            <button
              onClick={showMoreCountries}
              className="text-primary-main text-[16px] leading-[24px] font-normal py-[12px] w-full text-left"
            >
              Show More
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CountryFilterDropdown;
