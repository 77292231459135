import React, { useEffect, Suspense} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AdminAuthRoutes, UnAuthRoutes, UserAuthRoutes } from "./routes";
import ErrorPage from "./components/pages/Error/ErrorPage";
import Spinner from "./assets/spinner/Spinner";
import Navigation from "./components/sharedComponent/Navigations";
import { useDispatch } from "react-redux";
import { getCountryList } from "./store/slices/countryDropdownSlice";
import { getLanguageList } from "./store/slices/languageDropdownSlice";
import { getCurrenciesList } from "./store/slices/currenciesDropdownSlice";
import { getRetailersList } from "./store/slices/commonDropDownSlice";
import { getCategoriesList } from "./store/slices/categoriesDropdownSlice";
import { getTestiMonials } from "./store/slices/commonhomepageSlice";
import { getAllTestiMonials } from "./store/slices/alltestimonialsSlice";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import {
  checkUserToken,
  getUserIdUsingGetApi,
} from "./store/api/validateToken";
import logout from "./_helperfunctions/Logout";
import "swiper/css";
import "swiper/css/pagination";
import { getUser } from "./store/slices/userSlice";
import { getFavoriteCategoryList} from "./store/slices/favoriteCategoriesSlice";
import { getStats } from "./store/slices/statsSlice";
import ScrollToTop from "./components/sharedComponent/ScrollToTop/ScrollToTop";
function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = Cookies.get("wd-team");

  const validateToken = async () => {
    const isTokenValid = await checkUserToken();
    if (!isTokenValid) {
      logout();
    }
  };

  useEffect(() => {
    if (token) {
      let userId;
      validateToken();
      getUserIdUsingGetApi()
        .then((res) => {
          userId = res.userId;
          if (userId) {
            dispatch(getUser({ userId, token }));
          }
        })
        .catch((error) => console.error(error));

      dispatch(getFavoriteCategoryList({ token }));
    }
    dispatch(getStats({ token }));
    dispatch(getCountryList());
    dispatch(getLanguageList());
    dispatch(getCurrenciesList());
    dispatch(getRetailersList());
    dispatch(getCategoriesList({ token }));
    dispatch(getTestiMonials());
    dispatch(getAllTestiMonials());
  }, [dispatch, token]);
  return (
    <Suspense fallback={<Spinner />}>
      {!location.pathname.includes("subscribe") &&
        !location.pathname.includes("join") &&
        !location.pathname.includes("/:referralType/:userId.html") && (
          <Navigation />
        )}
      <Routes>
        {/* Protected Admin Routes */}
        {AdminAuthRoutes}

        {/* Public Routes */}
        {UnAuthRoutes}

        {/* Protected User Routes */}
        {UserAuthRoutes}

        {/* Fallback Error Page */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
}

function AppWrapper() {
  return (
    <Router>
      <ScrollToTop/>
      <App />
    </Router>
  );
}

export default AppWrapper;
