import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { HIDDEN_SUPPLIERS } from "../api";
import logout from "../../_helperfunctions/Logout";
export const getHiddenSupplier = createAsyncThunk(
  "/getHiddenSupplier",
  async (data, { isRejectedWithValue }) => {
    try {
      if (data.dealId === "") {
        const response = await axios.get(`${HIDDEN_SUPPLIERS}`, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });
        return response.data;
      } else if (data.dealId !== "") {
        const response = await axios.get(
          `${HIDDEN_SUPPLIERS}?page=${data.dealId}`,
          {
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          }
        );

        return response.data; // Ensure this is the expected format
      }
    } catch (error) {
      return isRejectedWithValue(
        error.response?.data ||
          "Something went wrong while fetching hiddenSupplier."
      );
    }
  }
);

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const hiddenSuppliersSlice = createSlice({
  name: "hidden_supplier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHiddenSupplier.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getHiddenSupplier.fulfilled, (state, action) => {
        // Handle the case when the request is successful
        state.isLoading = false;
        state.data = action.payload.data; // Store the fetched data
      })
      .addCase(getHiddenSupplier.rejected, (state, action) => {
        // Handle the case when the request fails
        state.isLoading = false;
        state.error = action.payload || "Failed to load deals.";
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});

export default hiddenSuppliersSlice.reducer;
