import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { OTO_PURCHASE } from "../api";
import logout from "../../_helperfunctions/Logout";

// Async thunk for purchasing OTO
export const purchaseOto = createAsyncThunk(
  "otoPurchase/purchaseOto",
  async ({ token, transactionId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        OTO_PURCHASE,
        { transactionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Purchase API Error:", error); 
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: error.message || "An error occurred" });
      }
    }
  }
);

const otoPurchaseSlice = createSlice({
  name: "otoPurchase",
  initialState: {
    isLoading: false,
    redirectUrl: "",
    success: false,
    message: "",
    error: null,
  },
  reducers: {
    resetOtoPurchaseState: (state) => {
      state.isLoading = false;
      state.redirectUrl = "";
      state.success = false;
      state.message = "";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(purchaseOto.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(purchaseOto.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          const { redirectUrl, message } = action.payload.data;
          state.redirectUrl = redirectUrl;
          state.success = true;
          state.message = message;
        } else {
          state.error = action.payload.message || "Failed to complete the purchase.";
        }
      })
      .addCase(purchaseOto.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload && action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
        state.error = action.payload.message || "An error occurred during the purchase process.";
      });
  },
});

export const { resetOtoPurchaseState } = otoPurchaseSlice.actions;

export default otoPurchaseSlice.reducer;
