import React, { useEffect, useState } from "react";
import BuyerSellerNav from "./components/BuyerSellerNav";
import TopNav from "./components/TopNav";
import MainNav from "./components/MainNav";
import { useLocation, useNavigate } from "react-router-dom";
import { useRedirection, useMediaQuery } from "../../../hooks";
import { useSelector, useDispatch } from "react-redux";
import { fetchAuthStatus } from "../../../store/slices/authSlice";
import { useUser } from "../../../hooks";
import { getPackage } from "../../../store/slices/packageSlice";
const MobileHeader = React.lazy(() =>
  import("../MobileHeaderMenu/MobileHeader")
);
const MobileHeaderDown = React.lazy(() =>
  import("../MobileHeaderMenu/MobileHeaderDown")
);

const Navigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 1200px)"); // Define mobile breakpoint)
  const { getUserToken } = useUser();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();
  const [isPremium, setIsPremium] = useState(false);
  const [auth, setAuth] = useState(false);
  const isPremiums = useSelector((item) => item.package);
  const { redirectToLoginPage } = useRedirection();
  const isAuthRoute =
    location.pathname.includes("/login") ||
    location.pathname.includes("/reset-password");
  const ShowRegisterForm = () => {
    navigate("/login?tab=register");
  };
  const ShowLoginForm = () => {
    redirectToLoginPage();
  };
  useEffect(() => {
    getItems();
    if (isAuthenticated) {
      dispatch(getPackage(getUserToken()));
    }
  }, [isAuthenticated]);

  const getItems = async () => {
    const token = getUserToken();
    dispatch(fetchAuthStatus(token));
    if (isAuthenticated) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  };

  useEffect(() => {
    if (!isPremiums.isLoading && isPremiums.data) {
      const data = isPremiums.data;
      if (data.dealAccess == "Y" || data.comboAccess == "Y") {
        setIsPremium(true);
      } else {
        setIsPremium(false);
      }
    }
  }, [isPremiums.data, isPremiums.isLoading]);
  return (
    <>
      {" "}
      {auth && !isPremium && <TopNav isPremium={isPremium} />}
      {!auth && <TopNav />}
      <BuyerSellerNav />
      {!isAuthRoute && !isMobile && (
        <MainNav ShowRegisterForm={ShowRegisterForm} />
      )}
      {!isAuthRoute && (
        <MobileHeader
          ShowRegisterForm={ShowRegisterForm}
          ShowLoginForm={ShowLoginForm}
          isMobile={isMobile}
        />
      )}
      {!isAuthRoute && (
        <MobileHeaderDown isMobile={isMobile} isPremium={isPremium} />
      )}
    </>
  );
};

export default Navigation;
