import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../hooks/cookies";
import { AFFILIATE_DASHBOARD } from "../api";

export const fetchAffiliateDashboard = createAsyncThunk(
  "affiliateDashboard/fetchAffiliateDashboard",
  async (reportType, { rejectWithValue }) => {
    try {
      const token = getToken();

      const response = await axios.post(
        AFFILIATE_DASHBOARD,
        { reporttype: reportType },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue("No response from server");
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const affiliateDashboardSlice = createSlice({
  name: "affiliateDashboard",
  initialState: {
    data: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    resetAffiliateDashboardState: (state) => {
      state.data = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAffiliateDashboard.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(fetchAffiliateDashboard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchAffiliateDashboard.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.payload?.message || "Failed to fetch affiliate dashboard data";
      });
  },
});

export const { resetAffiliateDashboardState } = affiliateDashboardSlice.actions;
export default affiliateDashboardSlice.reducer;
