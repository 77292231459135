import { useNavigate } from "react-router-dom";
import { routes } from "../routes/route";

const useRedirection = () => {
  const navigate = useNavigate();

  const redirectAdminDashboard = () => {
    navigate("/user/admin-dashboard");
  };
  const redirectUserDashboard = () => {
    navigate("/user/dashboard");
  };
  const redirectLogin = () => {
    navigate("/");
  };
  const redirectToLoginPage = () => {
    navigate("/login");
  };
  const redirectPasswordReset = (token) => {
    if (token) {
      navigate(`/reset-password/${token}`);
    }
  };
  const redirectAccountProfile = () => {
    navigate("/user/account-profile");
  };
  const redirectToAddDeals = () => {
    navigate("/user/add-deals");
  };
  const redirectToDeals = () => {
    navigate(`/${routes.dealsSlug}/main`);
  };
  const redirectoSearchDeals = (data) => {
    navigate(`/${routes.dealsSlug}/${data}`);
  };
  const redirectoDealSearch = (data) => {
    navigate(`/${routes.deals}?search=${data}`);
  };
  const redirectoSupplierSearch = (data) => {
    navigate(`/${routes.deals}?search=${data}`);
  };
  const redirectoEditDeals = (id) => {
    navigate(`/user/edit-deals/${id}`);
  };
  const redirectToSupplierProfile = () => {
    navigate("/user/supplier-profile");
  };
  const redirectToSubscribe = () => {
    navigate("/subscribe");
  };
  const redirectToSubscribeSuccess = () => {
    navigate("/subscribe-success");
  };
  const redirectToTour = () => {
    navigate("/tour-step-1");
  };
  const redirectoManageDeals = () => {
    navigate("/user/manage-deals");
  };
  const redirectTotestimonials = () => {
    navigate("/all-testimonials");
  };
  const redirectToSingleDeal = (data) => {
    navigate(`${routes.single_deals_root}${data}`);
  };
  const redirectToCart = () => {
    navigate("/user/my-deals-basket");
  };
  const redirectToFav = () => {
    navigate("/user/my-favourites");
  };
  const redirectToSingleSupplier = (data) => {
    navigate(`${routes.single_supplier_root}${data}`);
  };

  const redirectToHome = () => {
    navigate("/");
  };
  const redirectToAllSuppliers = () => {
    navigate("/suppliers/main");
  };
  const redirectToAlphabeticSearch = () => {
    navigate("/alphabetical-keyword-index");
  };
  const redirectToContact = () => {
    navigate("/contact-us");
  };
  const redirectToFaq = () => {
    navigate("/faq");
  };

  const   redirectToPrivacyPolicy= () => {
    navigate("/privacy-policy");
  };

  const redirectToTermAndUse = () => {
    navigate("/terms-and-conditions");
  };
  const redirectToCookies = () => {
    navigate("/cookies");
  };
  const redirectoJoin=()=>{
    navigate("/join")
  }
  const redirectToMessages=()=>{
    navigate("/user/messages")
  }
  const redirectToManageBills=()=>{
    navigate("/user/manage-services")
  }
  return {
    redirectAdminDashboard,
    redirectUserDashboard,
    redirectLogin,
    redirectPasswordReset,
    redirectAccountProfile,
    redirectToAddDeals,
    redirectToLoginPage,
    redirectToDeals,
    redirectoSearchDeals,
    redirectoEditDeals,
    redirectToSupplierProfile,
    redirectToSubscribe,
    redirectToSubscribeSuccess,
    redirectToTour,
    redirectoManageDeals,
    redirectToSingleDeal,
    redirectToSingleSupplier,
    redirectoDealSearch,
    redirectToCart,
    redirectTotestimonials,
    redirectToHome,
    redirectToFav,
    redirectToAllSuppliers,
    redirectToAlphabeticSearch,
    redirectToContact,
    redirectToFaq,
    redirectoSupplierSearch,
    redirectToPrivacyPolicy,
    redirectToTermAndUse,
    redirectToCookies,
    redirectoJoin,
    redirectToMessages,
    redirectToManageBills
  };
};
export default useRedirection;
