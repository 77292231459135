import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import eyeoff from "../../../../../assets/images/v1/deals/eyeoff.svg";
import heart from "../../../../../assets/images/v1/icons/heart-rounded.svg";
import liked from "../../../../../assets/images/v1/deals/heart-rounded-liked.svg";
import amazon from "../../../../../assets/images/website/amazon2.png";
import ebay from "../../../../../assets/images/website/ebay.png";
import Modal from "../Modal/Modal";
import LoginForm from "../LoginForm/LoginForm";
import LikeForm from "../LikeForm/LikeForm";
import { getToken } from "../../../../../hooks/cookies";
import { useMediaQuery, useRedirection } from "../../../../../hooks";
import { getScrollerDeals } from "../../../../../store/slices/scrollDealsSlice";
import { trimText } from "../../constant";
import {
  getCurrencyCode,
  getCurrencySymbol,
} from "../../../../../store/slices/currencySlice";
import { getConvertedRate } from "../../../../sharedComponent/Calculation/CurrencyCalculation";
import { onUnLikeClick } from "../../../../../store/api/likeDeals";
import { onHideClick } from "../../../../../store/api/hideShowDeals";
import { getCommonDeals } from "../../../../../store/slices/commonDealsSlice";
import badge from "../../../../../assets/images/v1/icons/badge.svg";

const unitList = {
  "Per Piece": "Pieces",
  "Per Pair": "Pairs",
  "Per Box": "Boxes",
  "Per Pallet": "Pallets",
  "Per Lot": "Lots",
  "Per Kilogram": "Kilograms",
};

const Card = ({
  image,
  title,
  price,
  prices = [],
  markup,
  rrp,
  inVat,
  grossProfit,
  featuredDate,
  comparison = [],
  units,
  likeButtonShow,
  like = [],
  id,
  categoryId,
  slug,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 1200px)"); // Define mobile breakpoint)
  const { redirectToSingleDeal } = useRedirection();
  const isToken = getToken();

  const currencySymbol = useSelector(getCurrencySymbol);
  const currencyCode = useSelector(getCurrencyCode);
  const retailer = useSelector((state) => state.common.data);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(like.length > 0);

  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page")) || 1;
  const initialSearchMode = queryParams.get("searchMode") || "";
  const initialSortBy = queryParams.get("sortBy") || "";
  const initialSearchTerm = queryParams.get("search")?.split(",") || [];
  const initialCountry =
    queryParams.get("country")?.split(",").map(Number) || [];
  const initialGrade = queryParams.get("product_condition")?.split(",") || [];

  const [currentPage] = useState(initialPage);
  const [searchTerm] = useState(initialSearchTerm);
  const [searchMode] = useState(initialSearchMode);
  const [sortBy] = useState(initialSortBy);
  const [selectedGradeOptions] = useState(initialGrade);
  const [selectedCountries] = useState(initialCountry);

  const token = getToken();

  useEffect(() => {
    setIsLiked(like.length > 0);
  }, [like]);

  const dispatchCommonDeals = useCallback(() => {
    dispatch(
      getCommonDeals({
        dealId: categoryId || "",
        page: `${currentPage}`,
        search: searchTerm,
        searchMode,
        sortBy,
        country: selectedCountries,
        product_condition: selectedGradeOptions,
        token,
      })
    );
  }, [
    dispatch,
    categoryId,
    currentPage,
    searchTerm,
    searchMode,
    sortBy,
    selectedCountries,
    selectedGradeOptions,
    token,
  ]);

  const unLike = () => {
    onUnLikeClick(id).then(() => {
      setIsLiked(false);
      dispatchCommonDeals();
      dispatch(getScrollerDeals());
    });
  };

  const hideCard = () => {
    onHideClick({ dealId: id }).then(() => {
      dispatchCommonDeals();
      dispatch(getScrollerDeals());
    });
  };

  const getDealImageAlt = (retailerID) => {
    const retailerData = retailer.find(
      (item) => item.fldRetailerId === retailerID
    );
    return retailerData?.fldRetailerName || "";
  };

  const getDealImage = (retailerID) => {
    const retailerData = retailer.find(
      (item) => item.fldRetailerId === retailerID
    );
    const retailerName = retailerData?.fldRetailerName.toLowerCase();

    if (retailerName === "amazon") {
      return amazon;
    } else if (retailerName === "ebay") {
      return ebay;
    }
    return null;
  };

  const getSingleUnitList = (value) => unitList[value[0]] || "";

  const onSingleDeal = () => {
    redirectToSingleDeal(slug);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const renderComparison = () => {
    return comparison.map((platform, index) => (
      <div
        className="flex items-center justify-between py-3 border-b"
        key={index}
      >
        <div className="flex items-center gap-2">
          <img
            src={getDealImage(platform.fldRetailerId)}
            alt={getDealImageAlt(platform.fldRetailerId)}
            className="w-8 h-8"
          />
          <div>
            <h4 className="font-bold text-sm text-neutral-900">
              {currencySymbol}
              {getConvertedRate(platform.fldDealSamplePrice, currencyCode)}
            </h4>
            <p className="text-xs text-neutral-500">exc.VAT</p>
          </div>
        </div>
        <a
          className="text-sm font-semibold text-primary-main"
          target="_blank"
          rel="noopener noreferrer"
          href={platform.fldDealSampleLink || "#"}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Verify
        </a>
      </div>
    ));
  };

  return (
    <div className="w-full bg-white border border-neutral-200 rounded-xl overflow-hidden flex flex-col relative">
      <div className="w-[100px] h-[69px] relative">
        <img
          src={badge}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
          }}
          alt="badge"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <p className="text-[12px] leading-[18px] font-semibold text-white text-center">
            %Markup
          </p>
          <h1 className="font-bold text-secondary-main text-[16px] leading-[24px] text-center phone:text-[14px] phone:leading-[21px]">
            {markup}%
          </h1>
        </div>
      </div>
      
      <div className="w-full px-[20px] py-[8px] mt-[16px] mr-[16px]">
        <button onClick={onSingleDeal} className="w-full h-auto">
          <img
            src={image}
            className="w-full h-48 object-contain rounded-[16px] mx-auto"
            alt="product"
          />
        </button>
      </div>

      {/* Product Info */}
      <div className="p-4">
        <div className="flex items-center justify-between">
          <p className="text-[20px] phone:text-[16px] phone:leading-[24px] font-bold leading-[27px] text-secondary-main">
            {currencySymbol}
            {getConvertedRate(price, currencyCode)}
            <span className="font-normal phone:text-[12px] phone:leading-[18px] text-[14px] leading-[21px] text-neutral-500">
              {inVat ? "inc.Vat " : "ex.VAT"}
            </span>
          </p>
          {!isMobile && likeButtonShow && (
            <div className="flex items-center gap-4">
              <button
                className="relative"
                onClick={isToken ? hideCard : openModal}
              >
                <img src={eyeoff} alt="Hide" className="w-6 h-6" />
              </button>
              {isLiked ? (
                <button className="relative" onClick={unLike}>
                  <img src={liked} alt="Like" className="w-6 h-6" />
                </button>
              ) : (
                <button className="relative" onClick={openModal}>
                  <img src={heart} alt="Like" className="w-6 h-6" />
                </button>
              )}
            </div>
          )}
        </div>

        <p className="text-[12px] font-normal leading-[18px] text-neutral-600 my-[4px]">
          Deal First Featured On: {featuredDate}
        </p>

        <button
          onClick={onSingleDeal}
          className="font-bold text-md text-left text-[14px] leading-[21px] text-neutral-900 block hover:text-[#0355A5] transition-all duration-300 line-clamp-2 overflow-hidden"
        >
          {isMobile ? trimText(title, 25) : title}
        </button>

        {!isMobile && (
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <p className="text-xs text-neutral-600">RRP</p>
              <h4 className="font-bold text-sm text-secondary-main">
                {currencySymbol}
                {getConvertedRate(rrp, currencyCode)}
              </h4>
            </div>
            <div>
              <p className="text-xs text-neutral-600">Gross Profit</p>
              <h4 className="font-bold text-sm text-secondary-main">
                {currencySymbol}
                {getConvertedRate(grossProfit, currencyCode)}
                <span className="text-neutral-600">
                  / {units}{" "}
                  {getSingleUnitList(prices.map((item) => item.fldPriceType))}
                </span>
              </h4>
            </div>
          </div>
        )}

        {/* Comparison Section */}
        {!isMobile && comparison.length > 0 && (
          <div className="mt-4 bg-neutral-50 rounded-lg p-3">
            <p className="text-xs text-neutral-600 border-b pb-3">
              Compare with other platform
            </p>
            {renderComparison()}
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        customWidth={isToken ? "desktop:40vw" : ""}
      >
        {isToken ? (
          <LikeForm
            id={id}
            closeModal={closeModal}
            dispatchCommonDeals={dispatchCommonDeals}
          />
        ) : (
          <LoginForm setIsModalOpen={setIsModalOpen} />
        )}
      </Modal>
    </div>
  );
};

export default Card;
