import React, { useEffect, useState } from "react";
import FilterDropdown from "./FilterDropdown";
import CountryFilterDropdown from "../CountryFilterDropdown";
import { useSelector } from "react-redux";
import GradeDropDown from "../GradeDropDown";
import FilterDescriptionDropdown from "./FilterDescriptionDropdown";
import { useLocation } from "react-router-dom";
import ParentCategoryDropdown from "../../../SupplierCategory/Components/Filter/ParentCategoryDropdown";
import { useNavigate } from "react-router-dom";
const Filter = ({
  setSelectedCountries,
  selectedCountries,
  setSelectedGradeOptions,
  selectedGradeOptions,
  page,
}) => {
  const navigate = useNavigate();
  const [isMain, setIsMain] = useState(false);
  const countries = useSelector((state) => state.country.data);
  const categories = useSelector((state) => state.categories.data);
  const deals = useSelector((state) => state.common_deals.data);

  const location = useLocation();

  // Get the current page, search query, and search mode from query params

  const gradeOptions = [
    { label: "New", value: "N" },
    { label: "Used", value: "U" },
    { label: "Returns / Mixed Stock", value: "MR" },
    { label: "Liquidation Stocklots", value: "O" },
    { label: "Refurbished", value: "R" },
  ];
  const updateSearchParams = () => {
    const searchParams = new URLSearchParams();
    const queryParams = new URLSearchParams(location.search);
    if (selectedCountries && selectedCountries.length > 0) {
      const selectedCountryNames = countries
        .filter((country) => selectedCountries.includes(country.fldCountryId))
        .map((country) => country.fldCountryName.toLowerCase());

      searchParams.set("country", selectedCountryNames.join("-"));
    } else {
      searchParams.delete("country");
    }
    if (selectedGradeOptions && selectedGradeOptions.length > 0) {
      searchParams.set("product_condition", selectedGradeOptions);
    }
    if (queryParams.get("search")) {
      searchParams.set("search", queryParams.get("search").split(","));
    }
    if (queryParams.get("searchMode")) {
      searchParams.set("searchMode", queryParams.get("searchMode").split(","));
    }
    if (queryParams.get("page")) {
      searchParams.set("page", queryParams.get("page"));
    }

    if (queryParams.get("sortBy")) {
      searchParams.set("sortBy", queryParams.get("sortBy").split(","));
    }
    if (queryParams.get("page")) {
      searchParams.set("page", queryParams.get("page").split(","));
    }

    const searchParamsString = searchParams.toString();
    // if (searchParamsString) {
    //   navigate(`/deals/main?${searchParamsString}`);
    // }
    if (searchParamsString) {
      navigate(`${location.pathname}?${searchParamsString}`, { replace: true });
    } else {
      navigate(`${location.pathname}`, { replace: true });
    }
  };

  useEffect(() => {
    if (selectedGradeOptions.length > 0 || selectedCountries.length > 0) {
      updateSearchParams();
    }
  }, [selectedGradeOptions, selectedCountries,page]);
  
  useEffect(() => {
    if (location.pathname == "/deals/main") {
      setIsMain(true);
    } else {
      setIsMain(false);
    }
  }, [location.pathname]);

  return (
    <div className="w-full filter-panel phone:hidden tablet:hidden grid rounded-[16px] bg-white">
      <div className="w-full border-b border-neutral-200 py-[16px] px-[20px]">
        <h1 className="font-bold text-[18px] leading-[27px] text-neutral-900">
          Filter
        </h1>
      </div>

      {!isMain && (
        <FilterDropdown categories={categories} deals={deals} main={isMain} />
      )}
      <ParentCategoryDropdown
        categories={categories}
        deals={deals}
        main={isMain}
        issupplier={false}
      />
      <CountryFilterDropdown
        countries={countries}
        selectedCountries={selectedCountries || []}
        setSelectedCountries={setSelectedCountries}
      />
      {/* Grade */}
      <GradeDropDown
        title={"Grade"}
        options={gradeOptions}
        selectedGradeOptions={selectedGradeOptions}
        setSelectedGradeOptions={setSelectedGradeOptions}
      />
      {deals.categoryDescription && (
        <FilterDescriptionDropdown
          isOpenProps={true}
          heading={deals.categoryTitle}
          description={deals.categoryDescription}
        />
      )}
    </div>
  );
};

export default Filter;
