// customSourcingSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SAVE_COUSTOMSOURCING } from "../api";

export const saveCustomSourcingRequest = createAsyncThunk(
  "customSourcing/saveRequest",
  async ({ payload, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(SAVE_COUSTOMSOURCING, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : { message: "Network Error" }
      );
    }
  }
);

const customSourcingSlice = createSlice({
  name: "customSourcing",
  initialState: {
    loading: false,
    success: false,
    message: "",
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveCustomSourcingRequest.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(saveCustomSourcingRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload.message;
        state.data = action.payload.data;
      })
      .addCase(saveCustomSourcingRequest.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export default customSourcingSlice.reducer;
