import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import IconButton from "./components/IconButton";
import { decodeHtmlEntities } from "./constants";
import { routes } from "../../../routes/route";
import menu from "../../../assets/images/v1/icons/menu.svg";
import { useSearchParams } from "react-router-dom";
const AllCategories = () => {
  const location = useLocation();
  const categories = useSelector((state) => state.categories.data);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const business_type = searchParams.get("business_type");
  const [openMenu, setOpenMenu] = useState({});
  const [selectedOuterCategory, setSelectedOuterCategory] = useState(null);
  const [selectedInnerCategory, setSelectedInnerCategory] = useState(null);

  const toggleMenu = (slug) => {
    if (location.pathname === "/") {
      setOpenMenu((prev) => ({ ...prev, [slug]: true }));
    } else {
      setOpenMenu((prev) => ({ ...prev, [slug]: !prev[slug] }));
    }
  };

  const updateUrlWithCategory = (categorySlug) => {
    let newPath = "";
    if (business_type) {
      newPath= `/${
        !getFirstUrl(location.pathname)
          ? routes.dealsSlug
          : getFirstUrl(location.pathname)
      }/${categorySlug}?business_type=${business_type}`;
    } else {
    newPath= `/${
        !getFirstUrl(location.pathname)
          ? routes.dealsSlug
          : getFirstUrl(location.pathname)=="deals" || getFirstUrl(location.pathname)=="suppliers" ?getFirstUrl(location.pathname):routes.dealsSlug
      }/${categorySlug}`;
    }
    navigate(newPath); // Update the URL
  };
  const getFirstUrl = (data) => {
    const datas = data.split("/");
    return datas[1];
  };
  useEffect(() => {
    if (location.pathname === "/") {
      setOpenMenu({ main: true });
    } else {
      setOpenMenu({});
    }
  }, [location.pathname]);

  const handleCategoryClick = (
    event,
    categorySlug,
    categoryName,
    isInnerCategory = false,
    parentCategory = null
  ) => {
    if (isInnerCategory) {
      event.stopPropagation();
      setSelectedOuterCategory(parentCategory);
      setSelectedInnerCategory({ slug: categorySlug, name: categoryName });
    } else {
      setSelectedOuterCategory({ slug: categorySlug, name: categoryName });
      setSelectedInnerCategory(null);
    }
    updateUrlWithCategory(categorySlug);
    setOpenMenu({});
  };
  useEffect(() => {
    const pathParts = location.pathname.split("/").filter(Boolean);

    if (pathParts.length > 1) {
      const categorySlug = pathParts[pathParts.length - 1];
      const outerCategory = categories.find((cat) => cat.slug === categorySlug);

      if (outerCategory) {
        setSelectedOuterCategory({
          slug: outerCategory.slug,
          name: outerCategory.name,
        });

        // Check for an inner category if applicable
        const innerCategory = outerCategory.children.find(
          (child) => child.slug === categorySlug
        );
        if (innerCategory) {
          setSelectedInnerCategory({
            slug: innerCategory.slug,
            name: innerCategory.name,
          });
        } else {
          setSelectedInnerCategory(null);
        }
      }
    } else {
      setSelectedOuterCategory(null);
      setSelectedInnerCategory(null);
    }
  }, [location.pathname, categories]);

  const renderNestedMenu = (items, parentCategory) => (
    <ul className="absolute bg-white border w-72 hidden group-hover:block transition-all duration-300">
      {items.map((item) => (
        <li
          key={item.slug}
          className={`relative group p-4 hover:bg-[#FAF1DC] cursor-pointer flex justify-between items-center transition-all duration-300 ${
            selectedInnerCategory && selectedInnerCategory.slug === item.slug
              ? "bg-[#FAF1DC] font-bold"
              : ""
          }`}
          onMouseEnter={() => toggleMenu(item.slug)}
          onMouseLeave={() => toggleMenu(item.slug)}
          onClick={(event) =>
            handleCategoryClick(
              event,
              item.slug,
              item.name,
              true,
              parentCategory
            )
          }
        >
          <span>
            {decodeHtmlEntities(item.name)} ({item.supplierCount})
          </span>
          {item.children.length > 0 && (
            <span className="ml-2">
              <IconButton iconOnly={true} tabName={"chevronright"} />
            </span>
          )}
          {item.children.length > 0 && openMenu[item.slug] && (
            <div className="absolute left-full top-0 z-10">
              {renderNestedMenu(item.children, {
                slug: item.slug,
                name: item.name,
              })}
            </div>
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <div className="relative ">
      <button
        onClick={() => toggleMenu("main")}
        className="allcategories w-[314px] py-[12px] border-r border-neutral-200 min-w-[314px] flex items-center gap-[10px]"
        aria-expanded={Object.keys(openMenu).length > 0 ? "true" : "false"}
      >
        <img src={menu} alt="menu" />
        <p className="font-semibold text-[16px] leading-[24px] text-neutral-900">
          All Categories
        </p>
      </button>

      <ul
       className={`absolute mt-0.5 bg-white w-full transition-all duration-300 ease-in-out ${
        openMenu["main"] ? "block" : "hidden"
      }`}
      >
        {categories.map((category) => (
          <li
            key={category.slug}
            className={`group relative p-5 hover:bg-[#FAF1DC] hover:border-l-4 hover:border-orange-500 cursor-pointer flex justify-between items-center transition-all duration-300 ${
              selectedOuterCategory &&
              selectedOuterCategory.slug === category.slug
                ? "bg-[#FAF1DC] font-bold"
                : ""
            }`}
            onMouseEnter={() => toggleMenu(category.slug)}
            onMouseLeave={() => toggleMenu(category.slug)}
            onClick={(event) =>
              handleCategoryClick(event, category.slug, category.name, false)
            }
          >
            <span>
              <IconButton tabName={category.name} />
            </span>
            {category.children.length > 0 && (
              <span className="ml-2">
                <IconButton iconOnly={true} tabName={"chevronright"} />
              </span>
            )}
            {category.children.length > 0 && openMenu[category.slug] && (
              <div className="absolute left-full top-0 z-10">
                {renderNestedMenu(category.children, {
                  slug: category.slug,
                  name: category.name,
                })}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AllCategories;
