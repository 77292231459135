import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedGradeOptions: [],
  businessType: "", // Stores 'deals', 'liquidator', 'dropshipper', or empty string
};

const businessTypeSlice = createSlice({
  name: "businessType",
  initialState,
  reducers: {
    setSelectedGradeOption: (state, action) => {
      state.selectedGradeOptions = action.payload;
      if (action.payload.includes("fldSupplierTypeLiquidator")) {
        state.businessType = "fldSupplierTypeLiquidator";
      } else if (action.payload.includes("dropshipper")) {
        state.businessType = "dropshipper";
      } else if (action.payload.includes("deals")) {
        state.businessType = "deals";
      } else if (action.payload.includes("fldSupplierTypeWholeSaleSuppliers")) {
        state.businessType = "fldSupplierTypeWholeSaleSuppliers";
      } 
    },
  },
});

export const { setSelectedGradeOption } = businessTypeSlice.actions;

export const selectBusinessType = (state) => state.businessType.businessType;

export default businessTypeSlice.reducer;