// redux/slices/editDealsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_MANAGE_DEALSBYID } from "../api";
import logout from "../../_helperfunctions/Logout";

export const getDealsByID = createAsyncThunk(
  "/getDealsByID",
  async ({ dealId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_MANAGE_DEALSBYID}?dealId=${dealId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.data?.message === "Forbidden: Invalid Token") {
        logout(); // Handle token expiration by logging out
      }
      return rejectWithValue(
        error.response?.data || "Error fetching deal data."
      );
    }
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};

const editDealsSlice = createSlice({
  name: "editDeals",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDealsByID.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Clear errors on each request
      })
      .addCase(getDealsByID.fulfilled, (state, action) => {
        state.data = action.payload?.data || [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getDealsByID.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default editDealsSlice.reducer;
