import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0, // Ensure it's the very top of the page
      left: 0, // Reset horizontal scroll as well, just in case
      behavior: "smooth", // Smooth scrolling effect
    });
  }, [location]);

  return null; // This component doesn't render anything
};

export default ScrollToTop;
