import React, { useEffect, useState } from "react";
import { findCategoryPath } from "../../constant";
import OfferHeaderComponent from "../OfferHeaderComponent/OfferHeaderComponent";
import { useSelector } from "react-redux";
import { routes } from "../../../../../routes/route";
import { useMediaQuery } from "../../../../../hooks";
import { truncateTitle } from "../../../../../utils/paymentsMethods";
import { Link } from "react-router-dom";
const Breadcrumb = ({ id, singleDeals, type, mainState, total }) => {
  const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);
  const isMobile = useMediaQuery("(max-width: 1200px)"); // Define mobile breakpoint)
  const categories = useSelector((state) => state.categories.data);
  const categoryPath = findCategoryPath(categories, id);
  const singleCategoryPath = categoryPath.length
    ? categoryPath[categoryPath.length - 1]
    : null;

  useEffect(() => {
    const links = categoryPath.map((category, index) => ({
      label: category.name.replace(/&amp;/g, "&"),
      href:
        type == "suppliers"
          ? `/suppliers/${category.slug}`
          : `/${routes.dealsSlug}/${category.slug}`,
      isActive: index === categoryPath.length - 1,
    }));
    const lastObject = links[links.length - 1];
    {
      type == "single-deals"
        ? setBreadcrumbLinks([lastObject])
        : setBreadcrumbLinks(links);
    }
  }, [id, categories])
  return (
    <>
      <div
        className={`w-full  items-center gap-[4px] ${
          isMobile ? "block" : "flex"
        }`}
      >
        {!mainState && (
          <>
            {" "}
            <a className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] font-normal text-neutral-500">
              {type == "deals" && "Wholesale Deals"}
              {type == "suppliers" && "Wholesale Suppliers"}
              {type == "fldSupplierTypeLiquidator" && "Liquidators"}
              {type == "dropshipper" && "Dropshippers"}
            </a>
            <Link
              to="/deals/main"
              className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] font-normal text-neutral-500"
            >
              {type == "single-deals" && "Wholesale Deals"}
            </Link>
            <span className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] font-normal text-neutral-500">
              /
            </span>
          </>
        )}
        {breadcrumbLinks.length > 0 ? (
          breadcrumbLinks.map((link, index) => (
            <React.Fragment key={index}>
              <a
                href={link.href}
                className={`text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] font-normal 
                ${
                  singleDeals
                    ? "text-neutral-500"
                    : link.isActive
                    ? "text-neutral-900 font-medium"
                    : "text-neutral-500"
                }`}
              >
                {link.label}
              </a>
              {singleDeals ? (
                <span className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] font-normal text-neutral-500">
                  /
                </span>
              ) : (
                index < breadcrumbLinks.length - 1 && (
                  <span className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] font-normal text-neutral-500">
                    /
                  </span>
                )
              )}
            </React.Fragment>
          ))
        ) : mainState ? (
          <span className="text-neutral-500">
            {type == "deals" && "Wholesale Deals"}
            {type == "suppliers" && "Wholesale Suppliers"}
            {type == "fldSupplierTypeLiquidator" && "Liquidators"}
            {type == "dropshipper" && "Dropshippers"}
            {type == "single-deals" && "Wholesale Deals"}
          </span>
        ) : (
          <span className="text-neutral-500">No breadcrumb available</span>
        )}
        {singleDeals ? truncateTitle(singleDeals.fldDealTitle, 3) : ""}
      </div>
      {!singleDeals && (
        <OfferHeaderComponent
          type={type}
          mainState={mainState}
          breadcrumbs={breadcrumbLinks}
          categoryPath={singleCategoryPath}
          id={id}
          count={total}
        />
      )}
    </>
  );
};

export default Breadcrumb;
