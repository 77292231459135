import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMostPopularDeals } from "../../../../../store/slices/mostPopularDealsSlice";
import Card from "../Card/Card";
import { formatDate, getLowestPriceObject, trimArray } from "../../constant";
import medal from "../../../../../assets/images/v1/icons/medal.svg";
import { getProductingleImage } from "../../../../sharedComponent/ImageMaster/ImageMaster";
import { calculateProfitAndOrderUnits } from "../../../../sharedComponent/Calculation/Calculation";

const MostPopularOffers = ({ categoryId }) => {
  const dispatch = useDispatch();
  const { data: deals} = useSelector(
    (state) => state.mostPopularDeals
  );

  useEffect(() => {
    if (categoryId) {
      dispatch(fetchMostPopularDeals(categoryId));
    }
  }, [categoryId, dispatch]);

  // Check if deals data is empty or undefined. If so, hide the component.
  if (!deals || !deals.length) {
    return null;
  }

  const trimmedDeals = trimArray(deals, 4);

  return (
    <div className="w-full bg-white phone:hidden">
      <div className="w-full max-w-[1440px] mx-auto pt-[72px] px-[56px] pb-[40px] tablet:pb-[68px] phone:py-[48px] phone:px-[24px]">
        <div className="w-full flex phone:flex-col phone:gap-[24px] phone:items-start items-center justify-between">
          <div className="w-fit flex items-center phone:items-start tablet:items-start gap-[12px]">
            <img
              src={medal}
              className="w-[23px] h-[32px] phone:w-[18px] phone:h-[24px]"
              alt="medal"
            />
            <h3 className="text-[32px] phone:text-[20px] phone:leading-[27px] tablet:text-[32px] tablet:leading-[40px] tablet:max-w-[430px] font-bold leading-[40px] text-neutral-900">
              Most Popular Offers
            </h3>
          </div>
        </div>

        <div className="w-full phone:hidden grid tablet:grid-cols-2 grid-cols-4 gap-[20px] mt-[48px]">
          {trimmedDeals.map((deal, index) => (
            <Card
              key={index}
              image={getProductingleImage(deal.fldDealImage1)}
              inVat={getLowestPriceObject(deal.prices).fldIncVAT}
              title={deal.fldDealTitle || "Title NA"}
              price={getLowestPriceObject(deal.prices).fldPrice || "NA"}
              markup={deal.fldDealMarkup || "Markup NA"}
              rrp={deal.fldDealRRP || "RRP NA"}
              grossProfit={calculateProfitAndOrderUnits(deal)}
              featuredDate={formatDate(deal.fldDealCreationDate) || "Date NA"}
              units={deal.fldMinimumOrder}
              prices={deal.prices}
              fldDealCurrencyId={deal.fldDealCurrencyId}
              comparison={
                deal.samples && deal.samples.length ? deal.samples : null
              }
              slug={deal.fldDealSlug}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MostPopularOffers;
