import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_SUPPLIER_ORDER} from "../api";
import logout from "../../_helperfunctions/Logout";
export const getSellerOrderDetails = createAsyncThunk(
  "order/getSellerOrderDetails",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_SUPPLIER_ORDER, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    
      return response.data;
      
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const sellerOrderSlice = createSlice({
  name: "sellerOrder",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSellerOrderDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSellerOrderDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        
      })
      .addCase(getSellerOrderDetails.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});

export default sellerOrderSlice.reducer;
