import React from "react";
import AllCategoriesNav from "../../sharedComponent/AllCategories/components/AllCategoriesNav";
import CounterSection from "../../sharedComponent/CounterSection";

import Footer from "../../sharedComponent/Footer";
import {useSelector } from "react-redux";
import RelatedSearches from "./components/RelatedSearches/RelatedSearches";
import DealDetails from "./components/DealDetails/DealDetails";
import MostPopularOffers from "./components/MostPopularOffers/MostPopularOffers";
import Deals from "./Deals";
import NeverMiss from "../common/NeverMiss";
const DealWrapper = () => {
  const mostPopularOffers = useSelector(
    (state) => state.wholesalesuppliers.data
  );
  // Get the current page, search query, and search mode from query params
  const deals = useSelector((state) => state.common_deals.data);
  return (
    <div className="bg-[#F8FAFC] hello">
      <CounterSection />
      <AllCategoriesNav />
      {/* <MobileFilterComponent /> */}
      <Deals main={false} />
      {mostPopularOffers && mostPopularOffers.length ? (
        <MostPopularOffers data={mostPopularOffers} />
      ) : (
        ""
      )}
      {deals?.categoryDescription && <DealDetails deals={deals} />}
      <RelatedSearches />
      <NeverMiss/>
      {/* End Form Login */}
      <Footer />
    </div>
  );
};

export default DealWrapper;
