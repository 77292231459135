import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Breadcrumb from "./components/Breadcrumb/Breadcrumb";
import SearchFilterComponent from "./components/SearchFilterComponent/SearchFilterComponent";
import CardContainer from "./components/Card/CardContainer";
import Pagination from "./components/Pagination/Pagination";
import TopOffers from "./components/TopOffers/TopOffers";

import { getCommonDeals } from "../../../store/slices/commonDealsSlice";
import { getScrollerDeals } from "../../../store/slices/scrollDealsSlice";
import { mostPopularRecentDeals } from "../../../store/slices/mostPopularRecentSlice";
import { getPackage } from "../../../store/slices/packageSlice";
import {
  GetCurrentUrl,
  getIdBySlug,
  getSlugFromUrl,
  joinStringArray,
  findCategoryPath,
} from "./constant";
import MobileTabletFilterComponent from "./components/MobileFilterComponent/MobileFilterComponent";
import Filter from "./components/Filter/Filter";
import SubscribeCard from "./components/SubscribeCard/SubscribeCard";
import NoDealsFound from "./components/NoDealsFound/NoDealsFound";
import { getToken } from "../../../hooks/cookies";
import { getFavoriteCategoryList } from "../../../store/slices/favoriteCategoriesSlice";
import SubscribeSection from "./components/SubscribeSection/SubscribeSection";
import { useMediaQuery } from "../../../hooks";
import SearchResultNotice from "./components/SearchResultNotice/SearchResultNotice";
import DealsSearchHeading from "./components/DealsSearchHeading/DealsSearchHeading ";
const Deals = ({ main }) => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 1200px)");

  const { data } = useSelector((state) => state.scroller_deals);

  const dispatch = useDispatch();
  const token = getToken();
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page") || "1");
  const initialSearchMode = queryParams.get("searchMode") || "";
  const initialSortBy = queryParams.get("sortBy") || "";
  const initialSearchTerm = queryParams.get("search")
    ? queryParams.get("search").split(",")
    : [];

  const searchInputRef = useRef(null);
  const { deals, totalDeals } = useSelector((state) => state.common_deals.data);
  const { isLoading } = useSelector((state) => state.common_deals);
  const initialCountry = queryParams.get("country")
    ? queryParams
        .get("country")
        .split("/")
        .map((name) => name.trim())
    : [];

  const initialGrade = [];
  const isPremiums = useSelector((item) => item.package);
  const categories = useSelector((state) => state.categories.data);
  const countries = useSelector((state) => state.country.data);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [isMain, setIsMain] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState(initialCountry);
  const [auth, setAuth] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [isPremium, setIsPremium] = useState(false);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchMode, setSearchMode] = useState(initialSearchMode);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [searchValues, setSearchValues] = useState(initialSearchTerm);
  const [selectedGradeOptions, setSelectedGradeOptions] =
    useState(initialGrade);

  const slug = getSlugFromUrl(GetCurrentUrl(location));
  const id = getIdBySlug(categories, slug);

  useEffect(() => {
    if (categories && id) {
      const categoryPath = findCategoryPath(categories, id);
      if (categoryPath.length > 0) {
        setCategoryName(categoryPath[categoryPath.length - 1].name);
      }
    }
  }, [categories, id]);

  const selectedCountryNames =
    selectedCountries && countries
      ? selectedCountries
          .map((countryId) => {
            const found = countries.find(
              (country) => Number(country.fldCountryId) === Number(countryId)
            );
            return found ? found.fldCountryName : null;
          })
          .filter(Boolean)
      : [];

  const itemsPerPage = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (token) {
      dispatch(getFavoriteCategoryList({ token }));
    }
  }, []);

  useEffect(() => {
    if (!isPremiums.isLoading && isPremiums.data) {
      const data = isPremiums.data;
      if (data.dealAccess == "Y" || data.comboAccess == "Y") {
        setIsPremium(true);
      } else {
        setIsPremium(false);
      }
    }
  }, [isPremiums.data, isPremiums.isLoading]);

  useEffect(() => {
    getItems();
    if (isAuthenticated) {
      dispatch(getPackage(token));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (location.pathname == "/deals/main") {
      setIsMain(true);
    } else {
      setIsMain(false);
    }
  }, [location.pathname]);

  const getItems = async () => {
    if (isAuthenticated) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  };

  const handleSearch = (newSearchTerm, newSearchMode, newSortBy) => {
    setSearchTerm(newSearchTerm);
    setSearchMode(newSearchMode);
    setSortBy(newSortBy);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setSortBy(queryParams.get("sortBy") || "");
    setSearchMode(queryParams.get("searchMode") || "");
    setSearchTerm(
      queryParams.get("search") ? queryParams.get("search").split(",") : []
    );
    setSearchValues(
      queryParams.get("search") ? queryParams.get("search").split(",") : []
    );
  }, [location.search]);

  useEffect(() => {
    if (!countries || countries.length === 0) return;

    const countriesFromURL = queryParams.get("country")
      ? queryParams
          .get("country")
          .split("-")
          .map((name) => name.trim())
      : [];
    const mappedCountryIds = countriesFromURL
      .map((name) => {
        const country = countries.find(
          (c) => c.fldCountryName.toLowerCase() === name.toLowerCase()
        );
        return country ? country.fldCountryId : null;
      })
      .filter((id) => id !== null);

    setSelectedCountries(mappedCountryIds);
  }, [location.search, countries]);

  useEffect(() => {
    if (!isMobile) {
      if (location.pathname === "/deals/main") {
        dispatch(
          getCommonDeals({
            dealId: "",
            page: `${currentPage}`,
            search: searchTerm,
            searchMode,
            sortBy,
            country: selectedCountries,
            product_condition: selectedGradeOptions,
            token,
          })
        );
      } else {
        if (id) {
          dispatch(
            getCommonDeals({
              dealId: id,
              page: `${currentPage}`,
              search: searchTerm,
              searchMode,
              sortBy,
              country: selectedCountries,
              product_condition: selectedGradeOptions,
              token,
            })
          );
        }
      }

      // Pass `id` when dispatching `getScrollerDeals`
      dispatch(getScrollerDeals({ id }));
      dispatch(mostPopularRecentDeals());
    }
  }, [
    currentPage,
    searchTerm,
    searchMode,
    sortBy,
    selectedGradeOptions,
    selectedCountries,
    id,
    location.pathname,
    isAuthenticated,
    location.search,
    isMobile,
    dispatch,
    token,
  ]);

  const resetFilters = () => {
    setSearchValues([]);
    setSearchTerm([]);
    setSearchMode("any");
    setSortBy("");
  };

  const removeSearchAndSearchModeFromURL = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("search");
    queryParams.delete("searchMode");
  };

  const [showOrangeCard] = useState(Math.random() >= 0.5);

  return (
    <div className="w-full max-w-[1440px] mx-auto px-[56px] phone:px-[16px] flex items-start">
      <div className="w-[314px] min-w-[314px] py-[24px]  desktop:grid gap-[24px] phone:hidden tablet:hidden">
        <Filter
          main={main}
          selectedCountries={selectedCountries}
          setSelectedCountries={setSelectedCountries}
          selectedGradeOptions={selectedGradeOptions}
          setSelectedGradeOptions={setSelectedGradeOptions}
          page={currentPage}
        />
        {auth && !isPremium && (
          <>
            {showOrangeCard ? (
              <SubscribeCard classNames="bg-primary-main" />
            ) : (
              <SubscribeCard classNames="bg-additional-blue" />
            )}
          </>
        )}
        {!auth && (
          <>
            {showOrangeCard ? (
              <SubscribeCard classNames="bg-primary-main" />
            ) : (
              <SubscribeCard classNames="bg-additional-blue" />
            )}
          </>
        )}
      </div>
      <div className="w-full desktop:pl-[48px] phone:pl-[10px] pt-[24px] phone:pt-[32px] pb-[72px] phone:pb-[32px] tablet:pb-[56px]">
        {/* Breadcrumb */}
        <Breadcrumb
          categories={categories}
          id={id}
          mainState={isMain}
          type="deals"
          total={totalDeals}
        />
        {isMobile && (
          <MobileTabletFilterComponent
            handlePageChange={handlePageChange}
            auth={auth}
            isPremium={isPremium}
          />
        )}
        {/* Breadcrumb end */}
        <SearchFilterComponent
          onSearch={handleSearch}
          setSearchTerm={setSearchTerm}
          searchInputRef={searchInputRef}
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          selectedCountries={selectedCountries}
          countries={countries}
          categories={categories}
          page={currentPage}
        />
        {/* {data && data.length && <TopOffers searchTerm={searchTerm} />} */}
        {data && data.length > 0 && (
          <TopOffers searchTerm={searchTerm} id={id} />
        )}
        {searchTerm && searchTerm.length > 0 && (
          <>
            <DealsSearchHeading
              dealsCount={deals && deals.length}
              keyword={joinStringArray(searchTerm)}
              selectedCountries={selectedCountryNames}
              countries={countries}
              categories={categories}
              categoryName={categoryName} // Pass the category name here
            />
            <SearchResultNotice />
          </>
        )}
        <CardContainer
          deals={deals}
          searchTerm={searchTerm}
          isLoading={isLoading}
          removeSearchAndSearchModeFromURL={removeSearchAndSearchModeFromURL}
          searchInputRef={searchInputRef}
          keyword={joinStringArray(searchTerm)}
          resetFilters={resetFilters}
        />
        {deals && deals.length != 0 ? (
          totalDeals > 10 && (
            <Pagination
              totalItems={totalDeals}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              setCurrentPage={setCurrentPage}
            />
          )
        ) : searchTerm && searchTerm.length ? (
          <>
            <NoDealsFound
              searchInputRef={searchInputRef}
              setSearchTerm={setSearchTerm}
              keyword={joinStringArray(searchTerm)}
              removeSearchAndSearchModeFromURL={
                removeSearchAndSearchModeFromURL
              }
              resetFilters={resetFilters}
            />
            {auth && !isPremium && <SubscribeSection />}
            {!auth && <SubscribeSection />}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Deals;
