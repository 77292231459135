import React from "react";
import subscribe from "../../../../../assets/images/v1/deals/subscribe.svg";
import { useRedirection } from "../../../../../hooks";
const SubscribeCard = ({ classNames }) => {
  const { redirectToSubscribe } = useRedirection();
  return (
    <div
      className={`w-full  rounded-[16px] p-[24px] grid gap-[24px] ${classNames}`}
      style={{cursor:"pointer"}}
    >
      {/* Subscribe Icon */}
      <img
        src={subscribe}
        className="w-[80px] h-[68px] mx-auto"
        alt="subscribe-icon"
      />

      {/* Text Section */}
      <div>
        <h3 className="font-semibold text-[18px] leading-[27px] text-white text-center mb-[8px]">
        Subscribe for Full Access
        </h3>
       
        <div className="text-white opacity-80 text-[14px] leading-[21px] text-center space-y-4">
  <p>
    Unlock tens of thousands of verified liquidation, wholesale, and dropshipping suppliers 
    from across the EU, UK, North America, and beyond.
  </p>
  <p>
    Source fast-selling products at profit margins of 45% to 95%, all backed by our 
    custom sourcing guarantee — ensuring you find the suppliers you need at the profits 
    you deserve.
  </p>
  <p>
    Ready to supercharge your retail business?
  </p>
  <p>Wholesale Deals: the web’s largest and most trusted source of verified trade distributors.</p>
</div>

      </div>

      {/* Subscribe Button */}
      <p
        onClick={redirectToSubscribe}
        className="flex items-center justify-center text-primary-main bg-white rounded-[8px] text-[14px] leading-[21px] font-semibold w-[160px] h-[40px] text-center mx-auto hover:bg-neutral-gray transition-all duration-200"
      >
        Subscribe
      </p>
    </div>
  );
};

export default SubscribeCard;
