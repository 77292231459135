// src/store/store.js
import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import userSlice from "./slices/userSlice";
import passwordSlice from "./slices/passwordSlice";
import countryDropdownSlice from "./slices/countryDropdownSlice";
import languageDropdownSlice from "./slices/languageDropdownSlice";
import buyerSlice from "./slices/buyerSlice";
import categoriesDropdownSlice from "./slices/categoriesDropdownSlice";
import currenciesDropdownSlice from "./slices/currenciesDropdownSlice";
import supplierSlice from "./slices/supplierSlice";
import dealsReducer from "./slices/dealsSlice";
import elasticSearchReducer from "./slices/elasticSearchSlice";
import commonDropDownReducer from "./slices/commonDropDownSlice";
import addDealsReducer from "./slices/addDealsSlice";
import commonDealsReducer from "./slices/commonDealsSlice";
import wholesalesuppliersReducer from "./slices/wholesalesuppliersSlice";
import scrollerDealsSlice from "./slices/scrollDealsSlice";
import editDealsSlice from "./slices/editDealsSlice";
import commonhomepageSlice from "./slices/commonhomepageSlice";
import latestSuppliersSlice from "./slices/latestSuppliersSlice";
import favoriteCategoriesSlice from "./slices/favoriteCategoriesSlice";
import alltestimonialsSlice from "./slices/alltestimonialsSlice";
import reportsSlice from "./slices/reportsSlice";
import singleDealSlice from "./slices/singleDealSlice";
import todoSlice from "./slices/todosSlice";
import currencySlice from "./slices/currencySlice";
import statsSlice from "./slices/statsSlice";
import flashsaleSlice from "./slices/flashsaleSlice";
import favoriteSuppliersSlice from "./slices/favoriteSuppliersSlice";
import supplierCategorySlice from "./slices/supplierCategorySlice";
import favoriteDealsSlice from "./slices/favoriteDealsSlice";
import hiddenSuppliersSlice from "./slices/hiddenSuppliersSlice";
import singleSupplierSlice from "./slices/singleSupplierSlice";
import hiddenDealsSlice from "./slices/hiddenDealsSlice";
import addtoCartSlice from "./slices/addtoCartSlice";
import getCartSlice from "./slices/getCartSlice";
import getSupplierBestDealsSlice from "./slices/getSingleSupplierDealsSlice";
import getSupplierCategoriesBestDealsSlice from "./slices/getSupplierCategoriesSlice";
import globalSearchSlice from "./slices/globalSearchSlice";
import businessTypeSlice from "./slices/businessTypeSlice";
import placeOrderSlice from "./slices/placeOrderSlice";
import alphabeticSearchSlice from "./slices/alphabeticSearchSlice";
import allSubscriberPlansSlice from "./slices/allSubscriberPlans";
import newsletterSlice from "./slices/newsletterSlice";
import sellerOrderSlice from "./slices/sellerOrderSlice";
import buyerOrderSlice from "./slices/buyerOrderSlice";
import cancelOrderReducer from "./slices/cancelOrderSlice";
import buyerOrdersDetailSlice from "./slices/buyerOrdersDetailSlice";
import supplierOrderDetailSlice from "./slices/supplierOrderDetailSlice";
import mostPopularRecentSlice from "./slices/mostPopularRecentSlice";
import paystraxSubscriptionSlice from "./slices/paystraxSubscriptionSlice";
import b2bSubscriptionSlice from "./slices/b2bSubscriptionSlice";
import paypalSubscriptionSlice from "./slices/paypalSubscriptionSlice";
import contactSlice from "./slices/contactSlice";
import conversationListSlice from "./slices/coversationListSlice"
import sendMessageSlice from "./slices/sendMessageSlice";
import receiveMessageSlice from "./slices/receiveMessageSlice";
import otoApplicableSlice from "./slices/otoApplicableSlice";
import otoPurchaseSlice from "./slices/otoPurchaseSlice";
import packageSlice from "./slices/packageSlice";
import affiliateDashboardSlice from "./slices/affiliateDashboardSlice";
import affiliateReferralSlice from "./slices/affiliateReferralSlice";
import customSourcingSlice from "./slices/customSourcingSlice";
import sourcingSlice from "./slices/sourcingSlice";
import purchaseHistorySlice from "./slices/purchaseHistorySlice";
import supplierDataSlice from "./slices/supplierDataSlice";
import relatedTopDealsSlice from "./slices/relatedDealsSlice";
import mostPopularDealsSlice from "./slices/mostPopularDealsSlice";
const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    password: passwordSlice,
    country: countryDropdownSlice,
    language: languageDropdownSlice,
    buyer: buyerSlice,
    categories: categoriesDropdownSlice,
    currencies: currenciesDropdownSlice,
    supplier: supplierSlice,
    deals: dealsReducer,
    elastic: elasticSearchReducer,
    common: commonDropDownReducer,
    addeals: addDealsReducer,
    common_deals: commonDealsReducer,
    scroller_deals: scrollerDealsSlice,
    wholesalesuppliers: wholesalesuppliersReducer,
    editdeals: editDealsSlice,
    favoriteCategories: favoriteCategoriesSlice,
    commonhomepage: commonhomepageSlice,
    latestsuppliers: latestSuppliersSlice,
    alltestimonials: alltestimonialsSlice,
    reports: reportsSlice,
    single_deal: singleDealSlice,
    todos: todoSlice,
    currency: currencySlice,
    home_stats: statsSlice,
    home_flashsale: flashsaleSlice,
    favoriteSuppliers: favoriteSuppliersSlice,
    supplierCategory: supplierCategorySlice,
    favoriteDeals: favoriteDealsSlice,
    hiddenSuppliers: hiddenSuppliersSlice,
    hiddenDeals: hiddenDealsSlice,
    single_supplier: singleSupplierSlice,
    addtoCart: addtoCartSlice,
    getCart: getCartSlice,
    order: placeOrderSlice,
    getSupplierBestDeals: getSupplierBestDealsSlice,
    getSupplierCategoriesBestDeals: getSupplierCategoriesBestDealsSlice,
    globalSearch: globalSearchSlice,
    businessType:businessTypeSlice,
    alphabeticSearch:alphabeticSearchSlice,
    allSubscriberPlans:allSubscriberPlansSlice,
    newsletter: newsletterSlice,
    buyerOrder: buyerOrderSlice,
    cancelOrder: cancelOrderReducer,
    sellerOrder: sellerOrderSlice,
    buyerOrders: buyerOrdersDetailSlice,
    supplierOrderDetail: supplierOrderDetailSlice,
    mostPopularRecent:mostPopularRecentSlice,
    paystraxSubscription: paystraxSubscriptionSlice,
    b2bSubscription: b2bSubscriptionSlice,
    paypalSubscription: paypalSubscriptionSlice,
    contact:contactSlice,
    conversationList:conversationListSlice,
    sendMessage:sendMessageSlice,
    receiveMessage:receiveMessageSlice,
    otoApplicable: otoApplicableSlice,
    otoPurchase: otoPurchaseSlice,
    package:packageSlice,
    affiliateDashboard: affiliateDashboardSlice,
    affiliateReferral: affiliateReferralSlice,
    customSourcing: customSourcingSlice,
    sourcing: sourcingSlice,
    purchaseHistory: purchaseHistorySlice,
    supplierData: supplierDataSlice,
    relatedTopDeals: relatedTopDealsSlice,
    mostPopularDeals:mostPopularDealsSlice,
  },
});

export default store;
