import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_SUPPLIER_DATA } from "../api";
import logout from "../../_helperfunctions/Logout";

export const getAllSupplierData = createAsyncThunk(
  "supplierData/getAllSupplierData",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_SUPPLIER_DATA, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: "Network Error" });
      }
    }
  }
);

// Initial state
const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

// Create the slice
const supplierDataSlice = createSlice({
  name: "supplierData",
  initialState,
  reducers: {
    clearSupplierData: (state) => {
      state.isLoading = false;
      state.data = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSupplierData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllSupplierData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getAllSupplierData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message || "Something went wrong";
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});

export const { clearSupplierData } = supplierDataSlice.actions;
export default supplierDataSlice.reducer;