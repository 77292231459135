import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SEND_MESSAGE } from "../api";
import logout from "../../_helperfunctions/Logout";
export const sendMessage = createAsyncThunk(
  "sendMessage",
  async (data, { rejectWithValue }) => {
    const { token, ...others } = data;
    try {
      const formData = new FormData();

      // Append all key-value pairs from `data` to FormData
      Object.entries(others).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const response = await axios.post(SEND_MESSAGE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const sendMessageSlice = createSlice({
  name: "sendMessage",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});

export default sendMessageSlice.reducer;
