import React, { useEffect } from "react";
import Card from "./Card";
import LoadingCard from "./LoadingCard";
import {
  formatDate,
  GetCurrentUrl,
  getIdBySlug,
  getLowestPriceObject,
  getSlugFromUrl,
  joinStringArray,
} from "../../constant";
import { getProductingleImage } from "../../../../sharedComponent/ImageMaster/ImageMaster";
import { useDispatch, useSelector } from "react-redux";
import { getFavoriteCategoryList } from "../../../../../store/slices/favoriteCategoriesSlice";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { calculateProfitAndOrderUnits } from "../../../../sharedComponent/Calculation/Calculation";
import NoDealMain from "../NoDealsFound/NoDealMain";
const CardContainer = ({
  deals,
  isLoading,
  searchTerm,
  removeSearchAndSearchModeFromURL,
  searchInputRef,
  resetFilters
}) => {
  const token = Cookies.get("wd-team");
  const location = useLocation();
  const dispatch = useDispatch();
  const favCategories = useSelector((state) => state.favoriteCategories.data);
  const categories = useSelector((state) => state.categories.data);
  const slug = getSlugFromUrl(GetCurrentUrl(location));
  const categoryId = getIdBySlug(categories, slug);
  useEffect(() => {
    if (favCategories && favCategories.length === 0) {
      if (token) {
        dispatch(getFavoriteCategoryList({ token }));
      }
    }
  }, []);
  if (isLoading) {
    return <LoadingCard />;
  }
  return (
    <div className="container mx-auto py-6">
      <div
        className={
          deals && deals.length
            ? "grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-6"
            : "w-full"
        }
      >
        {deals && deals.length
          ? deals.map((deal, index) => (
              <Card
                key={index}
                image={getProductingleImage(deal.fldDealImage1)}
                inVat={getLowestPriceObject(deal.prices).fldIncVAT}
                title={deal.fldDealTitle}
                price={getLowestPriceObject(deal.prices).fldPrice}
                markup={deal.fldDealMarkup}
                rrp={deal.fldDealRRP}
                grossProfit={calculateProfitAndOrderUnits(deal)}
                featuredDate={formatDate(deal.fldDealCreationDate)}
                units={deal.fldMinimumOrder}
                comparison={
                  (deal.samples && deal.samples.length && deal.samples) || [
                    {
                      name: "Amazon",
                      logo: "../../../../../assets/images/v1/homepage/amazon.svg",
                      price: "12.35",
                    },
                    {
                      name: "Ebay",
                      logo: "../../../../../assets/images/v1/homepage/ebay.svg",
                      price: "12.35",
                    },
                  ]
                }
                fldDealCurrencyId={deal.fldDealCurrencyId}
                likeButtonShow={true}
                like={deal.favorite}
                id={deal.fldDealId}
                categoryId={categoryId}
                prices={deal.prices}
                slug={deal.fldDealSlug}
              />
            ))
          : searchTerm.length
          ? ""
          : !isLoading && (
              <NoDealMain
                removeSearchAndSearchModeFromURL={
                  removeSearchAndSearchModeFromURL
                }
                searchInputRef={searchInputRef}
                keyword={joinStringArray(searchTerm)}
                resetFilters={resetFilters}
              />
            )}
      </div>
    </div>
  );
};

export default CardContainer;
