import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RELATED_TOP_DEALS } from "../api";

export const fetchRelatedTopDeals = createAsyncThunk(
  "relatedTopDeals/fetchRelatedTopDeals",
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${RELATED_TOP_DEALS}?categoryId=${categoryId}`);
   
      return response.data.data;
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const relatedTopDealsSlice = createSlice({
  name: "relatedTopDeals",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRelatedTopDeals.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchRelatedTopDeals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchRelatedTopDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default relatedTopDealsSlice.reducer;
