import { AuthGuard } from "../guards";
import { Route } from "react-router-dom";
import React from "react";
import { routes } from "./route";
import DealWrapper from "../components/pages/deals/DealWrapper";

const SingleDealsWrapper = React.lazy(() =>
  import("../components/pages/SingleDeals/SingleDealsWrapper")
);
const BuyerProfile = React.lazy(() =>
  import("../components/pages/user/Buyer/Profile/BuyerProfileWrapper")
);
const ManageDeals = React.lazy(() =>
  import("../components/pages/user/Common/Deals/ManageDeals/ManageDealsWrapper")
);
const SupplierDashboard = React.lazy(() =>
  import("../components/pages/user/Common/DashBoard/SupplierDashboard")
);
const SupplierProfile = React.lazy(() =>
  import("../components/pages/user/Seller/Profile/SupplierProfileWrapper")
);
const AccountProfile = React.lazy(() =>
  import("../components/pages/user/Common/account-profile/AccountProfile")
);
const AffiliateEarningsWrapper = React.lazy(() =>
  import("../components/pages/user/Common/AffiliateEarnings/AffiliateEarningsWrapper")
);
const ManageBills = React.lazy(() =>
  import("../components/pages/user/Common/ManageBilling/ManageBillsWrapper")
);
const Messages = React.lazy(() =>
  import("../components/pages/user/Common/Messages/MessagesWrapper")
);
const ManageServices = React.lazy(() =>
  import("../components/pages/user/Common/ManageServices/ManageServicesWrapper")
);
const Orders = React.lazy(() =>
  import("../components/pages/user/Common/Orders/OrderList/OrdersWrapper")
);
const OrderMessages = React.lazy(() =>
  import(
    "../components/pages/user/Common/Orders/OrderMessages/OrderMessagesWrapper"
  )
);
const OrderDetails = React.lazy(() =>
  import(
    "../components/pages/user/Common/Orders/OrderDetails/OrderDetailsWrapper"
  )
);
const AddNewDeals = React.lazy(() =>
  import("../components/pages/user/Common/Deals/AddDeals/AddNewDealsWrapper")
);
const Homepage = React.lazy(() =>
  import("../components/pages/Homepage/HomepageWrapper")
);
const EditDeals = React.lazy(() =>
  import("../components/pages/user/Common/Deals/EditDeals/EditDealsWrapper")
);

const MyFavourites = React.lazy(() =>
  import("../components/pages/user/Common/MyFavourites/MyFavouritesWrapper")
);
const DealsBasketFilled = React.lazy(() =>
  import(
    "../components/pages/user/Common/Cart/DealsBasketFilled/DealsBasketFilledWrapper"
  )
);
const Thankyou = React.lazy(() =>
  import("../components/pages/common/Thankyou/index.js")
);
const PaymentFailed = React.lazy(() =>
  import("../components/pages/common/PaymentFailed/index.js")
);

const CommonAuthRoutes = [
  <Route
    key="/user/dashboard"
    path="/user/dashboard"
    element={<AuthGuard component={<SupplierDashboard />} />}
  />,
  <Route
    key="slash"
    path="/"
    element={<AuthGuard component={<Homepage />} />}
  />,
  <Route
    key="/useraccount-profile"
    path="/user/account-profile"
    element={<AuthGuard component={<AccountProfile />} />}
  />,
  <Route
    key="/userbuyer-profile"
    path="/user/buyer-profile"
    element={<AuthGuard component={<BuyerProfile />} />}
  />,
  <Route
    key="/usersupplier-profile"
    path="/user/supplier-profile"
    element={<AuthGuard component={<SupplierProfile />} />}
  />,
  <Route
    key="/usermanage-deals"
    path="/user/manage-deals"
    element={<AuthGuard component={<ManageDeals />} />}
  />,

  <Route
    key="affiliate-earnings"
    path="/user/affiliate-earnings"
    element={<AuthGuard component={<AffiliateEarningsWrapper />} />}
  />,

  <Route
    key="/usermanage-bills"
    path="/user/manage-billing"
    element={<AuthGuard component={<ManageBills />} />}
  />,

  <Route
    key="/usermanage-services"
    path="/user/manage-services"
    element={<AuthGuard component={<ManageServices />} />}
  />,
  <Route
    key="/usermessages"
    path="/user/messages"
    element={<AuthGuard component={<Messages />} />}
  />,
  <Route
    key="/usermessages"
    path="/user/messages/:partnerId"
    element={<AuthGuard component={<Messages />} />}
  />,
  <Route
    key="/user/orders/:id"
    path="/user/orders/:id"
    element={<AuthGuard component={<Orders />} />}
  />,
  <Route
    key="/userorders-messages/:id"
    path="/user/orders-messages/:id"
    element={<AuthGuard component={<OrderMessages />} />}
  />,
  <Route
    key="/user/orders-details/:id"
    path="/user/orders-details/:id"
    element={<AuthGuard component={<OrderDetails />} />}
  />,
  <Route
    key="/user/add-deals"
    path="/user/add-deals"
    element={<AuthGuard component={<AddNewDeals />} />}
  />,
  <Route
    key={`${routes.dealsSlug}`}
    path={`/${routes.dealsSlug}/:slug`}
    element={<AuthGuard component={<DealWrapper />} />}
  />,
  <Route
    key="/user/edit-deals/:id"
    path="/user/edit-deals/:id"
    element={<AuthGuard component={<EditDeals />} />}
  />,
  <Route
    key={`${routes.single_deals}`}
    path={`/${routes.single_deals}`}
    element={<AuthGuard component={<SingleDealsWrapper />} />}
  />,

  <Route
    key="/user/my-favourites"
    path="/user/my-favourites"
    element={<AuthGuard component={<MyFavourites />} />}
  />,
  <Route
    key={`${routes.DealsBasket}`}
    path={`${routes.DealsBasket}`}
    element={<AuthGuard component={<DealsBasketFilled />} />}
  />,
  <Route
    key="/thank-you"
    path="/thank-you"
    element={<AuthGuard component={<Thankyou />} />}
  />,
  <Route
    key="/payment-failed"
    path="/payment-failed"
    element={<AuthGuard component={<PaymentFailed />} />}
  />,
];

export default CommonAuthRoutes;
