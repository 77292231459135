import React from "react";
import { Route } from "react-router-dom";
import { UnAuthGuard } from "../guards";
import { routes } from "./route";
const SingleDealsWrapper = React.lazy(() =>
  import("../components/pages/SingleDeals/SingleDealsWrapper")
);
const Login = React.lazy(() => import("../components/pages/Login/Login"));
const Homepage = React.lazy(() =>
  import("../components/pages/Homepage/HomepageWrapper")
);
const DealWrapper = React.lazy(() =>
  import("../components/pages/deals/DealWrapper")
);
const TourStep1 = React.lazy(() =>
  import("../components/pages/Tours/components/TourStep1")
);
const TourStep2 = React.lazy(() =>
  import("../components/pages/Tours/components/TourStep2")
);
const TourStep3 = React.lazy(() =>
  import("../components/pages/Tours/components/TourStep3")
);
const TourStep4 = React.lazy(() =>
  import("../components/pages/Tours/components/TourStep4")
);

const AllTestimonials = React.lazy(() =>
  import("../components/pages/common/Testimonials/AllTestimonialsWrapper")
);
const Subscribe = React.lazy(() =>
  import("../components/pages/common/Subscribe/SubscribeWrapper")
);

const SupplierCategoryWrapper = React.lazy(() =>
  import("../components/pages/SupplierCategory/SupplierCategoryWrapper")
);
const SingleSupplierWrapper = React.lazy(() =>
  import("../components/pages/SingleSupplier/SingleSupplierWrapper")
);
const AlphaBeticSearchWrapper = React.lazy(() =>
  import("../components/pages/AlphabeticSearch/AlphaBeticSearchWrapper")
);

const ContactWrapper = React.lazy(() =>
  import("../components/pages/Contact/ContactWrapper")
);

const FaqWrapper = React.lazy(() =>
  import("../components/pages/Faq/FaqWrapper")
);

const PrivacyPolicyWrapper = React.lazy(() =>
  import("../components/pages/PrivacyPolicy/PrivacyPolicyWrapper")
);
const TermAndUseWrapper = React.lazy(() =>
  import("../components/pages/TermUse/TermAndUseWrapper")
);
const CookiesWrapper = React.lazy(() =>
  import("../components/pages/Cookies/CookiesWrapper")
);
const Join = React.lazy(() => import("../components/pages/Join/Join.jsx"));
const AffiliateRedirect = React.lazy(() =>
  import("../components/pages/AffiliateRedirect/AffiliateRedirect.js")
);
const UnAuthRoutes = [
  <Route
    key="User Login"
    path="/login"
    element={<UnAuthGuard component={<Login />} />}
  />,
  <Route
    key="Reset-password"
    path="/reset-password/:token"
    element={<UnAuthGuard component={<Login />} />}
  />,
  <Route key="homepage" path="/" element={<Homepage />} />,
  <Route
    key={`${routes.dealsSlug}`}
    path={`/${routes.dealsSlug}/:slug`}
    element={<DealWrapper />}
  />,
  <Route key="tour-step-1" path="/tour-step-1" element={<TourStep1 />} />,
  <Route key="tour-step-2" path="/tour-step-2" element={<TourStep2 />} />,
  <Route key="tour-step-3" path="/tour-step-3" element={<TourStep3 />} />,
  <Route key="tour-step-4" path="/tour-step-4" element={<TourStep4 />} />,

  <Route
    key="all-testimonials"
    path="/all-testimonials"
    element={<AllTestimonials />}
  />,
  <Route
    key="subscribe"
    path="/subscribe"
    element={<UnAuthGuard component={<Subscribe />} />}
  />,
  <Route
    key={`${routes.single_deals}`}
    path={`/${routes.single_deals}`}
    element={<SingleDealsWrapper />}
  />,
  <Route
    key={`${routes.supplier_category_slug}`}
    path={`/${routes.supplier_category_slug}/:slug`}
    element={<SupplierCategoryWrapper />}
  />,
  <Route
    key={`${routes.single_supplier}`}
    path={`${routes.single_supplier}`}
    element={<SingleSupplierWrapper />}
  />,
  <Route
    key={`${routes.alphabetic_search}`}
    path={`${routes.alphabetic_search}`}
    element={<AlphaBeticSearchWrapper />}
  />,
  <Route key="contact-us" path="/contact-us" element={<ContactWrapper />} />,
  <Route key="faq" path="/faq" element={<FaqWrapper />} />,
  <Route
    key="privacy-policy"
    path="/privacy-policy"
    element={<PrivacyPolicyWrapper />}
  />,
  <Route key="faq" path="/faq" element={<FaqWrapper />} />,

  <Route
    key="privacy-policy"
    path="/privacy-policy"
    element={<PrivacyPolicyWrapper />}
  />,
  <Route
    key="terms-and-conditions"
    path="/terms-and-conditions"
    element={<TermAndUseWrapper />}
  />,
  <Route key="cookies" path="/cookies" element={<CookiesWrapper />} />,
  <Route key="join" path="/join" element={<Join />} />,
  <Route
    key="affiliateRedirect"
    path="/:referralType/:userId.html"
    element={<AffiliateRedirect />}
  />,
];

export default UnAuthRoutes;
