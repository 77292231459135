import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_ALL_SUBSCRIBER_PLAN } from "../api";

export const getAllSubscriberPlans = createAsyncThunk(
  "/getAllSubscriberPlans",
  async () => {
    const response = await axios.get(GET_ALL_SUBSCRIBER_PLAN);
    return response.data;
  }
);

const initialState = {
  data: {},
  isLoading: true,
  error: null,
};
const allSubscriberPlansSlice = createSlice({
  name: "allSubscriberPlans",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubscriberPlans.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAllSubscriberPlans.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSubscriberPlans.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload;
      });
  },
});
export default allSubscriberPlansSlice.reducer;
