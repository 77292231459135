import React, { useState, useRef, useEffect } from "react";
import chevronDown from "../../../assets/images/v1/icons/chevron-down.svg";
import chevronUp from "../../../assets/icons/chevron-up.png";
import { useRedirection } from "../../../hooks";

const BuyerDropDown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { redirectToLoginPage, redirectoJoin } = useRedirection();
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const redirectToLogin = () => {
    redirectToLoginPage();
    setIsOpen(false);
  };

  const redirectToJoin = () => {
    redirectoJoin();
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative select-dropdown-container" ref={dropdownRef}>
      <button
        className="forbuyer px-[6px] py-[3px] rounded-[6px] transition-all duration-300 flex items-center gap-[6px]"
        onClick={toggleDropdown}
      >
        <p className="font-normal text-[14px] leading-[21px] text-neutral-800">
          For buyers
        </p>
        <img
          src={isOpen ? chevronUp : chevronDown}
          className={`tablet:w-[16px] tablet:h-[16px] transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          } text-black`}
          width="16px"
          height="16px"
          alt="Chevron"
        />
      </button>
      {isOpen && (
        <div className="w-[136px] flex flex-col absolute mt-[7px] left-0 p-[4px] bg-white forbuyer-modal select-dropdown">
          <button
            className="buyer-benefit transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left pl-2 hover:bg-[#FAF1DC]"
            onClick={redirectToJoin}
          >
            Buyer Benefits
          </button>
          <button
            className="buyer-register transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left pl-2 hover:bg-[#FAF1DC]"
            onClick={redirectToLogin}
          >
            Register
          </button>
        </div>
      )}
    </div>
  );
};

export default BuyerDropDown;
