import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SUBCRIBE_NEWSLETTER } from "../api";
// Async thunk to call API
export const SUBSCRIBE_NEWSLETTER = createAsyncThunk(
  'newsletter/subscribe',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(SUBCRIBE_NEWSLETTER, { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

const newsletterSlice = createSlice({
  name: 'newsletter',
  initialState: {
    successMessage: null,
    errorMessage: null,
    loading: false,
  },
  reducers: {
    clearMessages: (state) => {
      state.successMessage = null;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SUBSCRIBE_NEWSLETTER.pending, (state) => {
        state.loading = true;
        state.successMessage = null;
        state.errorMessage = null;
      })
      .addCase(SUBSCRIBE_NEWSLETTER.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.message || 'Subscription successful!';
      })
      .addCase(SUBSCRIBE_NEWSLETTER.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload || 'Failed to subscribe!';
      });
  },
});

export const { clearMessages } = newsletterSlice.actions;

export default newsletterSlice.reducer;
