// src/slices/b2bSubscriptionSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../hooks/cookies";
import { SUBSCRIBER_B2B } from "../api";

// Thunk for handling B2B subscription
export const b2bSubscription = createAsyncThunk(
  "b2bSubscription/b2bSubscription",
  async (subscriptionData, { rejectWithValue }) => {
    try {
      const token = getToken();

      const response = await axios.post(SUBSCRIBER_B2B, subscriptionData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue("No response from server");
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const b2bSubscriptionSlice = createSlice({
  name: "b2bSubscription",
  initialState: {
    success: false,
    isLoading: false,
    error: null,
    redirectURL: null,
  },
  reducers: {
    resetB2BSubscriptionState: (state) => {
      state.success = false;
      state.isLoading = false;
      state.error = null;
      state.redirectURL = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(b2bSubscription.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
        state.redirectURL = null;
      })
      .addCase(b2bSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = action.payload.success;
        state.redirectURL = action.payload.data?.redirectUrl || null;
      })
      .addCase(b2bSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to process B2B subscription";
        state.redirectURL = null;
      });
  },
});

export const { resetB2BSubscriptionState } = b2bSubscriptionSlice.actions;
export default b2bSubscriptionSlice.reducer;
