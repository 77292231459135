// src/store/slices/affiliateReferralSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../hooks/cookies";
import { AFFILIATE_REFERRAL } from "../api";

// Async thunk to handle affiliate referral
export const handleAffiliateReferral = createAsyncThunk(
  "affiliateReferral/handleAffiliateReferral",
  async (referralData, { rejectWithValue }) => {
    try {
      const token = getToken();

      const response = await axios.post(AFFILIATE_REFERRAL, referralData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message || "Failed to handle referral");
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message || "Server Error");
      } else if (error.request) {
        return rejectWithValue("No response from server");
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const affiliateReferralSlice = createSlice({
  name: "affiliateReferral",
  initialState: {
    success: false,
    isLoading: false,
    error: null,
    redirectUrl: null,
    lastInsertId: null,
  },
  reducers: {
    resetAffiliateReferralState: (state) => {
      state.success = false;
      state.isLoading = false;
      state.error = null;
      state.redirectUrl = null;
      state.lastInsertId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleAffiliateReferral.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
        state.redirectUrl = null;
        state.lastInsertId = null;
      })
      .addCase(handleAffiliateReferral.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = action.payload.success;
        state.redirectUrl = action.payload.data?.redirectUrl || null;
        state.lastInsertId = action.payload.data?.lastInsertId || null;
      })
      .addCase(handleAffiliateReferral.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.payload?.message ||
          action.payload ||
          "Failed to handle affiliate referral";
        state.redirectUrl = null;
        state.lastInsertId = null;
      });
  },
});

export const { resetAffiliateReferralState } = affiliateReferralSlice.actions;
export default affiliateReferralSlice.reducer;
