import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ADD_TO_CART ,REMOVE_FROM_CART} from "../api";
import logout from "../../_helperfunctions/Logout";
export const AddtoCart = createAsyncThunk(
  "/AddtoCart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(ADD_TO_CART,{ dealId: data.dealId }, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const RemoveFromCart = createAsyncThunk(
  "/RemoveFromCart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(REMOVE_FROM_CART,{ dealId: data.dealId }, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const addtoCartSlice = createSlice({
  name: "addtoCart",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(AddtoCart.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(AddtoCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AddtoCart.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
        state.error = action.payload;
      })
      .addCase(RemoveFromCart.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(RemoveFromCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(RemoveFromCart.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
        state.error = action.payload;
      });
  
  },
});
export default addtoCartSlice.reducer;
