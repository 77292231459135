import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_MESSAGE} from "../api";
import logout from "../../_helperfunctions/Logout";
export const receiveMessage = createAsyncThunk(
  "receiveMessage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_MESSAGE}/${data.id}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const receiveMessageSlice = createSlice({
  name: "receiveMessage",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(receiveMessage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(receiveMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(receiveMessage.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});

export default receiveMessageSlice.reducer;
