import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { PURCHASE_HISTORY } from "../api";
import logout from "../../_helperfunctions/Logout"; // Ensure you have a logout utility

const initialState = {
  data: [],
  loading: false,
  error: null,
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    limit: 10, // Default items per page
  },
};

// Async thunk for fetching purchase history
export const fetchPurchaseHistory = createAsyncThunk(
  'purchaseHistory/fetchPurchaseHistory',
  async ({ token, page = 1 }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        PURCHASE_HISTORY,
        {}, // If there is no body, pass an empty object
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          params: { page }, // Include page in query params for pagination
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        logout(); // Log out if token is invalid
      }
      return rejectWithValue(
        error.response?.data || { message: 'Something went wrong while fetching purchase history.' }
      );
    }
  }
);

const purchaseHistorySlice = createSlice({
  name: 'purchaseHistory',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.pagination.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPurchaseHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data.data; // Data from API response
        state.pagination = {
          currentPage: action.payload.data.pagination.currentPage,
          totalPages: action.payload.data.pagination.totalPages,
          totalItems: action.payload.data.pagination.totalItems,
          limit: action.payload.data.pagination.limit,
        };
      })
      .addCase(fetchPurchaseHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { setCurrentPage } = purchaseHistorySlice.actions;
export default purchaseHistorySlice.reducer;
