import React, { useEffect, useState } from "react";
import chevronDown from "../../../../../assets/images/v1/icons/chevron-down.svg";
import search from "../../../../../assets/images/esources/suppliers/search.svg";
import SearchValueFilter from "./SearchValueFilter";
import { useMediaQuery } from "../../../../../hooks";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const SearchFilterComponent = ({
  onSearch,
  setSearchTerm,
  searchInputRef,
  searchValues,
  setSearchValues,
  page
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchMode, setSearchMode] = useState("");
  const [sortBy, setSortBy] = useState("");
  const isMobile = useMediaQuery("(max-width: 1200px)"); // Define mobile breakpoint)
  const [isOpenSort, setIsOpenSort] = useState(false);
  const updateSearchParams = () => {
    const searchParams = new URLSearchParams();
    const queryParams = new URLSearchParams(location.search);
    if (searchValue.trim()) {
      searchParams.set("search", searchValue.trim());
    }
    if (searchMode) {
      searchParams.set("searchMode", searchMode);
    }
    if (sortBy) {
      searchParams.set("sortBy", sortBy);
    }
    if (queryParams.get("product_condition")) {
      searchParams.set(
        "product_condition",
        queryParams.get("product_condition").split(",")
      );
    }
    if (page>1) {
      searchParams.set("page", page);
    }
    if (queryParams.get("country")) {
      searchParams.set("country", queryParams.get("country").split(","));
    }
    const searchParamsString = searchParams.toString();
    console.log("this is called 2" + searchParamsString);
    if (searchParamsString) {
      navigate(`/deals/main?${searchParamsString}`);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    updateSearchParams();
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchModeChange = (event) => {
    setSearchMode(event.target.value);
  };

  const handleSortBy = (event) => {
    setSortBy(event.target.value);
  };

  useEffect(() => {
    // Synchronize search params only when searchMode or sortBy changes
    if (searchMode || sortBy || page>1) {
      updateSearchParams();
    }
  }, [searchMode, sortBy,page]);
  useEffect(() => {
    const handlePopState = () => {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get("page");

      // Remove `page` from URL if it's 1
      if (pageParam === "1") {
        queryParams.delete("page");
        navigate(`/deals/main?${queryParams.toString()}`, { replace: true });
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location.search, navigate]);

  const resetFilters = () => {
    setSearchValue("");
    setSearchValues([]);
    setSearchMode("");
    setSortBy("");
    setSearchTerm("");
    navigate("/deals/main");
    // onSearch([], "", ""); // Trigger search reset with default values
  };

  const removeSearchValue = (index) => {
    // Remove the search term at the specific index
    if (searchValues.length < 1 || searchValues.length == 0) {
      setSearchValues([]);
      setSearchTerm("");
      onSearch([], searchMode, sortBy);
    } else {
      const updatedSearchValues = searchValues.filter((_, i) => i !== index);
      setSearchValues(updatedSearchValues);
      // Trigger the search with updated values
      onSearch(updatedSearchValues, searchMode, sortBy);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch(event);
    }
  };

  return (
    <>
      <div className="w-full flex tablet:flex-col tablet:items-start phone:flex-col phone:items-start tablet:gap-[12px] items-center justify-between tablet:my-[12px]">
        {/* Filter Inputs */}
        <div className="flex items-center phone:w-full phone:flex-col tablet:w-full w-[601px] relative">
          <div className="relative w-full phone:max-w-none max-w-[206px] flex items-center">
            <div className="w-[16px] h-[16px] bg-transparent flex items-center justify-between absolute right-[16px] bg-white pointer-events-none">
              <img
                data-icon="arrow"
                src={chevronDown}
                className={`w-[24px] h-[24px] transition-transform z-0${
                  isOpen ? "rotate-180" : ""
                }`}
                alt="dropdown arrow"
              />
            </div>
            <select
              onClick={() => setIsOpen(!isOpen)}
              value={searchMode}
              style={{cursor:"pointer"}}
              onChange={(e) => handleSearchModeChange(e)} // Update search mode
              className="py-[8px] w-full phone:w-full phone:border phone:rounded-t-[8px] phone:rounded-bl-[0px] phone:border-b-[0px] h-[39px] bg-white px-[16px] rounded-l-[8px] border border-r-[0px] border-neutral-300 text-[14px] leading-[21px] text-neutral-700 appearance-none"
            >
              <option value="any">Any of my keywords</option>
              <option value="all">All keywords in any order</option>
              <option value="exact">All keywords in the exact order</option>
            </select>
          </div>
          <div className="relative w-full">
            <input
              type="text"
              value={searchValue} // Single input field value
              onChange={handleInputChange}
              ref={searchInputRef}
              className="h-[39px] w-full phone:rounded-l-[8px] phone:rounded-t-[0px] py-[8px] px-[16px] rounded-r-[8px] border border-neutral-300 text-[14px] leading-[21px]"
              placeholder="Filter these results by keyword ..."
              name="search"
              id=""
              onKeyDown={handleKeyDown}
            />
            <img
              data-name="search-icon"
              src={search}
              className="w-[18px] h-[18px] phone:top-[12px] absolute top-[11px] right-[16px]"
              alt="Search icon"
              onClick={handleSearch} // Trigger search on icon click
            />
          </div>
        </div>

        {/* Sorting */}
        <div className="phone:hidden flex items-center w-[309px] gap-[8px] relative">
          <div className="w-[16px] h-[16px] bg-white flex items-center justify-between absolute right-3 pointer-events-none">
            <img
              data-icon="arrow"
              src={chevronDown}
              className={`w-[24px] h-[24px] transition-transform z-0${
                isOpenSort ? "rotate-180" : ""
              }`}
              alt="dropdown arrow"
            />
          </div>
          <p className="text-[14px] font-normal text-neutral-500 min-w-fit leading-[21px]">
            Sort offers :
          </p>
          <select
            onClick={() => {
              setIsOpenSort(!isOpenSort);
            }}
            style={{cursor:"pointer"}}
            value={sortBy}
            onChange={handleSortBy}
            className="w-full py-[8px] h-[38.4px] phone:w-full rounded-[8px] bg-white px-[16px] border border-neutral-300 text-[14px] leading-[21px] text-neutral-900 appearance-none"
          >
            <option value="best_match">Best Match</option>
            <option value="newest_first">Date Added: Newest First</option>
            <option value="oldest_first">Date Added: Oldest First</option>
            <option value="markup_high_to_low">%Markup: High to Low</option>
            <option value="markup_low_to_high">%Markup: Low to High</option>
            <option value="best_sellers_high_to_low">
              Best Sellers: High to Low
            </option>
            <option value="best_sellers_low_to_high">
              Best Sellers: Low to High
            </option>
            <option value="wholesale_price_low_to_high">
              Wholesale Price: Low to High
            </option>
            <option value="wholesale_price_high_to_low">
              Wholesale Price: High to Low
            </option>
            <option value="min_quantity_low_to_high">
              Minimum Quantity: Low to High
            </option>
            <option value="min_quantity_high_to_low">
              Minimum Quantity: High to Low
            </option>
            <option value="gross_profit_high_to_low">
              Gross Profit: High to Low
            </option>
            <option value="gross_profit_low_to_high">
              Gross Profit: Low to High
            </option>
            <option value="rrp_low_to_high">RRP: Low to High</option>
            <option value="rrp_high_to_low">RRP: High to Low</option>
            <option value="dropship_only">Dropship: Deals Only</option>
          </select>
        </div>
      </div>
      <SearchValueFilter
        searchValues={searchValues}
        removeSearchValue={removeSearchValue}
        resetFilters={resetFilters}
      />
      {isMobile && (
        <div className="desktop:hidden phone:w-full  flex items-center w-[309px] gap-[8px] relative">
          <div className="w-[16px] h-[16px] bg-white flex items-center justify-between absolute right-3 pointer-events-none">
            <img
              data-icon="arrow"
              src={chevronDown}
              className={`w-[24px] h-[24px] transition-transform z-0${
                isOpenSort ? "rotate-180" : ""
              }`}
              alt="dropdown arrow"
            />
          </div>
          <p className="text-[14px] font-normal text-neutral-500 min-w-fit leading-[21px]">
            Sort offers :
          </p>
          <select
            onClick={() => {
              setIsOpenSort(!isOpenSort);
            }}
            value={sortBy}
            onChange={handleSortBy}
            className="w-full py-[8px] h-[38.4px] phone:w-full rounded-[8px] bg-white px-[16px] border border-neutral-300 text-[14px] leading-[21px] text-neutral-900 appearance-none"
          >
            <option value="best_match">Best Match</option>
            <option value="newest_first">Date Added: Newest First</option>
            <option value="oldest_first">Date Added: Oldest First</option>
            <option value="markup_high_to_low">%Markup: High to Low</option>
            <option value="markup_low_to_high">%Markup: Low to High</option>
            <option value="best_sellers_high_to_low">
              Best Sellers: High to Low
            </option>
            <option value="best_sellers_low_to_high">
              Best Sellers: Low to High
            </option>
            <option value="wholesale_price_low_to_high">
              Wholesale Price: Low to High
            </option>
            <option value="wholesale_price_high_to_low">
              Wholesale Price: High to Low
            </option>
            <option value="min_quantity_low_to_high">
              Minimum Quantity: Low to High
            </option>
            <option value="min_quantity_high_to_low">
              Minimum Quantity: High to Low
            </option>
            <option value="gross_profit_high_to_low">
              Gross Profit: High to Low
            </option>
            <option value="gross_profit_low_to_high">
              Gross Profit: Low to High
            </option>
            <option value="rrp_low_to_high">RRP: Low to High</option>
            <option value="rrp_high_to_low">RRP: High to Low</option>
            <option value="dropship_only">Dropship: Deals Only</option>
          </select>
        </div>
      )}
    </>
  );
};

export default SearchFilterComponent;
