import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { MOST_POPULAR_DEALS } from "../api";

export const fetchMostPopularDeals = createAsyncThunk(
  "mostPopularDeals/fetch",
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${MOST_POPULAR_DEALS}?categoryId=${categoryId}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const mostPopularDealsSlice = createSlice({
  name: "mostPopularDeals",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMostPopularDeals.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchMostPopularDeals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchMostPopularDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default mostPopularDealsSlice.reducer;
