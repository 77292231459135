import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../hooks/cookies";
import { SUBSCRIBER_PAYSTRAX } from "../api";

// Thunk for handling Paystrax subscription
export const paystraxSubscription = createAsyncThunk(
  "paystraxSubscription/paystraxSubscription",
  async (subscriptionData, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await axios.post(SUBSCRIBER_PAYSTRAX, subscriptionData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue("No response from server");
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const paystraxSubscriptionSlice = createSlice({
  name: "paystraxSubscription",
  initialState: {
    success: false,
    isLoading: false,
    error: null,
    redirectURL: null,
  },
  reducers: {
    resetSubscriptionState: (state) => {
      state.success = false;
      state.isLoading = false;
      state.error = null;
      state.redirectURL = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(paystraxSubscription.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
        state.redirectURL = null;
      })
      .addCase(paystraxSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = action.payload.success;
        state.redirectURL = action.payload.data?.redirectUrl || null;
      })
      .addCase(paystraxSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to process subscription";
        state.redirectURL = null;
      });
  },
});

export const { resetSubscriptionState } = paystraxSubscriptionSlice.actions;
export default paystraxSubscriptionSlice.reducer;