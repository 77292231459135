import React, { useEffect, useRef, useState } from "react";
import chevronDown from "../../../assets/images/v1/icons/chevron-down.svg";
import chevronUp from "../../../assets/icons/chevron-up.png";
import { useSelector, useDispatch } from "react-redux";
import {
  getCurrencyCode,
  setCurrencyCode,
  setCurrencySymbol,
} from "../../../store/slices/currencySlice";

const CurrenciesDropDown = () => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dataValues, setDataValues] = useState([]);
  const currencyCode = useSelector(getCurrencyCode);
  const [selectedCurrency, setSelectedCurrency] = useState(currencyCode);
  const currencies = useSelector((item) => item.currencies);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleCurrencySelect = (currencyCode) => {
    const currencySymbol = dataValues.filter(
      (item) => item.fldCurrencyCode == currencyCode
    );
    dispatch(setCurrencyCode(currencyCode));
    dispatch(
      setCurrencySymbol(currencySymbol.map((item) => item.fldCurrencySymbol))
    );
    setSelectedCurrency(currencyCode);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!currencies.isLoading) {
      if (currencies.data) {
        setDataValues(currencies.data || []);
      }
    }
  }, [currencies.isLoading, currencies.data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative select-dropdown-container" ref={dropdownRef}> 
      <button
        className="open-language flex px-2 items-center gap-[6px] py-[3px] rounded-[6px] transition-all duration-300"
        onClick={toggleDropdown}
      >
        <span className="font-normal text-[14px] leading-[21px] text-neutral-800">{selectedCurrency}</span>
        <img
          src={isOpen ? chevronUp : chevronDown}
          className={`tablet:w-[16px] tablet:h-[16px] transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
          width="16px"
          height="16px"
          alt="Chevron"
        />
      </button>
      {isOpen && (
        <div className="w-[80px] flex flex-col absolute text-left mt-[7px] right-0 p-[4px] bg-white language-modal select-dropdown">
          {dataValues.map(
            (item) =>
              item.fldCurrencyCode !== selectedCurrency && (
                <button
                  key={item.fldCurrencyCode}
                  className="transition-all text-left pl-2 duration-300 w-full h-[32px] font-medium text-sm text-neutral-800 hover:bg-[#FAF1DC]"
                  onClick={() => handleCurrencySelect(item.fldCurrencyCode)}
                >
                  {item.fldCurrencyCode}
                </button>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default CurrenciesDropDown;
