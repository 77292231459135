import React from "react";
import orangex from "../../../../../assets/images/v1/deals/orangex.svg";
import trash from "../../../../../assets/images/v1/deals/trash.svg";

const SearchValueFilter = ({
  searchValues,
  removeSearchValue,
  resetFilters,
}) => {
  return (
    <div className="w-full my-[12px] phone:flex desktop:flex items-center gap-[8px] testing SearchValueFilter">
      {searchValues &&
        searchValues.length > 0 &&
        searchValues.map((val, index) => (
          <React.Fragment key={index}>
            <button
              className="px-[16px] py-[8px] flex items-center gap-[8px] bg-[#FAE1D4] rounded-[8px] text-[14px] leading-[21px] text-primary-main hover:bg-primary-surface/[0.75] hover:text-primary-hover"
              onClick={() => removeSearchValue(index)}
            >
              {val}
              <img
                src={orangex}
                className="w-[18px] h-[18px]"
                alt="Remove search term"
              />
            </button>
          </React.Fragment>
        ))}
      {searchValues && searchValues.length > 0 && (
        <button
          className="flex gap-2 items-center px-[16px] py-[8px]"
          onClick={resetFilters}
        >
          <p className="phone:hidden text-secondary-main text-[14px] font-semibold leading-[21px] tracking-[0.2px]">
            Reset Filters
          </p>
          <img
            src={trash}
            className="w-[18px] h-[18px] text-secondary-main"
            alt="Reset Filters"
          />
        </button>
      )}
    </div>
  );
};

export default SearchValueFilter;
