import React from "react";
import copy from "../../../assets/images/v1/icons/copy.svg";
import wholesalefooter from "../../../assets/images/v1/icons/wholesale-footer.svg";
import building from "../../../assets/images/v1/icons/building.svg";
import { Link } from "react-router-dom";

const RightsFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="w-screen bg-[#1F1F1F] border-t-[1.2px] border-[#040404]">
      <div className="phone:py-[32px] phone:px-[24px] phone:gap-6 tablet:py-[32px] tablet:px-[40px] px-[80px] py-[32px] flex phone:flex-col phone:items-start tablet:flex-row tablet:items-end tablet:gap-[48px] items-center justify-between">
        <div className="flex flex-col w-full gap-14 desktop:items-center desktop:flex-row">
          <div className="phone:w-full grid gap-[8px]">
            <div className="phone:w-full flex items-start gap-2">
              <img
                src={copy}
                className="w-[16px] h-[16px] mt-1"
                alt="copyright"
              />
              <p className="text-base text-white text-opacity-[0.6] font-normal">
                2004 - {currentYear} WholesaleDeals.co.uk, All Rights Reserved
              </p>
            </div>
            <div className="phone:w-full flex items-start gap-2">
              <img
                src={building}
                className="w-[16px] h-[16px] mt-1"
                alt="building"
              />
              <p className="text-base text-white text-opacity-60 font-normal">
                Tradegenius SL, Calle Villalba Hervas 2, 4-2 Santa Cruz de
                <span className="phone:hidden desktop:inline">
                  <br />
                </span>
                Tenerife, 38002 - Spain
              </p>
            </div>
          </div>
          <div className="w-fit phone:w-full phone:justify-between flex items-center gap-[40px]">
            <Link
              to="/terms-and-conditions"
              className="text-[16px] leading-[24px] text-white text-opacity-[0.6] font-normal"
            >
              Terms
            </Link>
            {/* <Link
              to="/cookies"
              className="text-[16px] leading-[24px] text-white text-opacity-[0.6] font-normal"
            >
              Cookies
            </Link> */}
            <Link
              to="/privacy-policy"
              className="text-[16px] leading-[24px] text-white text-opacity-[0.6] font-normal"
            >
              Privacy Policy
            </Link>
            <Link
              to="/contact-us"
              className="text-[16px] leading-[24px] text-white text-opacity-[0.6] font-normal"
            >
              Contact Us
            </Link>
          </div>
        </div>
        <div className="flex phone:flex-col phone:w-full items-center gap-[56px] phone:gap-[24px] tablet:justify-between tablet:w-fit">
          <Link to="/" className="no-underline">
            <img
              src={wholesalefooter}
              className="w-[228px] h-[34px]"
              alt="wholesale-footer"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RightsFooter;
