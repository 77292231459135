import { useEffect,Fragment, useState } from "react";
import { useLocation} from "react-router-dom"; // Import Navigate for redirection
import { useAuth, useRedirection } from "../hooks";
import { routesforAuth} from "../routes/route";

const UnAuthGuard = ({ component }) => {
  const [status, setStatus] = useState(false);
  const location = useLocation(); // Get the current route

  const { getUserInfo, logout } = useAuth();
  const { redirectUserDashboard } = useRedirection();

  useEffect(() => {
    checkCookie();
  }, [location.pathname]); // React to route changes
  const checkCookie = () => {
    try {
      const bypassRoutes =Object.values(routesforAuth[0]);
      
      const currentPath = location.pathname;

      if (bypassRoutes.some((route) => isRouteMatch(route, currentPath))) {
        const userInfo = getUserInfo();

        // Redirect if already logged in and trying to access '/userlogin'
        if (
          (currentPath === "/login" ||
            currentPath.includes("/reset-password") ||
            currentPath === "/deals") &&
          userInfo?.userId
        ) {
          redirectUserDashboard();
          return;
        }

        setStatus(true); // Allow access for other bypass routes
        return;
      }

      const userInfo = getUserInfo();
      if (!userInfo?.userId) {
        logout(); // Logout if no user info is available
      } else {
        redirectUserDashboard(); // Redirect to dashboard if logged in
      }
      setStatus(true);
    } catch (error) {
      logout(); // Logout on error
    }
  };

  const isRouteMatch = (pattern, path) => {
    const normalizedPattern = pattern.endsWith("/")
      ? pattern.slice(0, -1)
      : pattern;
    const normalizedPath = path.endsWith("/") ? path.slice(0, -1) : path;
    return normalizedPath.startsWith(normalizedPattern);
  };

  return status ? <Fragment>{component}</Fragment> : <></>;
};

export default UnAuthGuard;


