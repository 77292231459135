
// @description this slice is created for flashsale data
// @author Nidhi 
// @ data 2024-11-9

import { createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import { GET_FLASHSALE } from "../api";

export const getFlashsale = createAsyncThunk(
    "homeFlashsale/getaFlashsale",
    async (_, { rejectedWithValue }) =>{
        try {
            const response = await axios.get(GET_FLASHSALE);
         return response.data.data;
        } catch (error){
            return rejectedWithValue(
                error.response?.data || "Something went wrong while fetching flashsale."
            );
        }
    }
);

const initialState = {
    data: [],
    isLoading: false,
    error: null,
};

const flashsaleSlice= createSlice({
    name: "homeFlashsale",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getFlashsale.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(getFlashsale.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload[0];

        })
        .addCase(getFlashsale.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload || "Failed to fetch flashsale:";

        });
    },
});

export default flashsaleSlice.reducer;