import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SUBSCRIBE_NEWSLETTER, clearMessages } from "../../../store/slices/newsletterSlice"; // Adjust the path
import { getPackage } from "../../../store/slices/packageSlice"; // Adjust the path
import unionleft from "../../../assets/images/v1/icons/union-left.svg";
import unionright from "../../../assets/images/v1/icons/union-right.svg";

const NeverMiss = ({ token }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();

  const { successMessage, errorMessage, loading } = useSelector((state) => state.newsletter);
  const { data, isLoading, error: fetchError } = useSelector((state) => state.package);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // Fetch package details only for authenticated users (and if token exists)
  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch(getPackage(token));
    }
  }, [dispatch, token, isAuthenticated]);

  // Validate email
  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      setError("Email is required.");
      setIsValid(false);
    } else if (!emailRegex.test(value)) {
      setError("Please enter a valid email address.");
      setIsValid(false);
    } else {
      setError("");
      setIsValid(true);
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    validateEmail(email);
    if (isValid) {
      dispatch(SUBSCRIBE_NEWSLETTER(email));
    }
  };

  // Update email state and validate on input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  useEffect(() => {
    if (successMessage) {
      setEmail('');
    }
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        dispatch(clearMessages());
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage, dispatch]);

  // If user is authenticated, we may need to check package loading/errors.
  if (isAuthenticated && token) {
    if (isLoading) return <p>Loading...</p>;
    if (fetchError) return <p className="text-red-600">{fetchError.message}</p>;
  }

  // Determine whether to render the newsletter component.
  // - If user is not authenticated: render the component regardless.
  // - If user is authenticated: render it only if package data indicates not subscribed.
  const shouldRenderNewsletter =
    !isAuthenticated || (isAuthenticated && data?.newsLetterSubscribed === "N");

  return (
    shouldRenderNewsletter && (
      <div className="w-full bg-white">
        <div className="w-full max-w-[1440px] mx-auto phone:px-[24px] phone:py-[40px] tablet:py-[72px] tablet:px-[56px] py-[64px] px-[120px]">
          <div className="w-full relative overflow-hidden py-[48px] px-[64px] flex flex-col tablet:gap-[40px] gap-[32px] justify-center rounded-[24px] phone:py-[32px] phone:px-[20px] phone:gap-[32px] bg-[#FBD78B]">
            <img
              src={unionleft}
              className="absolute -bottom-[57px] -left-[48px] w-[185px] h-[179px]"
              alt="union decoration left"
            />
            <img
              src={unionright}
              className="absolute -right-[10px] -top-[28px] w-[224px] h-[216px]"
              alt="union decoration right"
            />
            <div className="text-center max-w-[540px] z-[30] mx-auto">
              <h1 className="font-bold text-[32px] leading-[40px] text-[#101828]">
                Never Miss A Wholesale Deal
              </h1>
              <p className="text-[18px] font-normal phone:mt-[12px] phone:text-[16px] phone:leading-[24px] tablet:mt-[12px] mt-[20px] leading-[27px] text-neutral-600">
                Get the hottest wholesale product offers directly to your inbox with our weekly newsletter
              </p>
            </div>
            <form
              id="formNewsletter"
              className="w-[486px] phone:w-full tablet:w-full mx-auto flex phone:flex-col phone:items-start items-start gap-[16px]"
              onSubmit={handleSubmit}
            >
              <div className="phone:w-full grid gap-[6px] phone:gap-[16px] tablet:gap-[16px]">
                <input
                  type="email"
                  name="email"
                  className={`py-[12px] phone:w-full tablet:w-full w-[360px] min-h-[48px] px-[14px] text-[16px] leading-[24px] rounded-[8px] ${
                    isValid ? "border-neutral-300" : "border-red-600 border"
                  }`}
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleInputChange}
                  required
                />
                {error && <p className="text-red-600 text-sm mt-2">{error}</p>}
              </div>
              <button
                className="newsletter-button phone:w-full py-[12px] z-[30] min-w-fit text-[16px] leading-[24px] font-medium text-white px-[20px] hover:bg-primary-hover transition-all duration-200 rounded-[8px] bg-[#E66927] capitalize"
                type="submit"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Alert me!"}
              </button>
            </form>
            {successMessage && <p className="text-green-600 text-center mt-4">{successMessage}</p>}
            {errorMessage && <p className="text-red-600 text-center mt-4">{errorMessage}</p>}
          </div>
        </div>
      </div>
    )
  );
};

export default NeverMiss;
