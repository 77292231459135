import React from "react";
import Card from "../Card/Card"; // Import your Card component here
import fireIcon from "../../../../../assets/images/v1/market/fire.svg";
import chevronIcon from "../../../../../assets/icons/chevron-orange.svg";
import { useSelector } from "react-redux";
import { formatDate, getLowestPriceObject, trimArray } from "../../constant";
import LoadingCard from "../Card/LoadingCard";

import { getProductingleImage } from "../../../../sharedComponent/ImageMaster/ImageMaster";
import { useMediaQuery } from "../../../../../hooks";
import { calculateProfitAndOrderUnits } from "../../../../sharedComponent/Calculation/Calculation";

const TopOffers = ({ searchTerm, id }) => {
  const { data, isLoading } = useSelector((state) => state.scroller_deals);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const deals = isMobile
    ? data?.length && trimArray(data, 2)
    : data?.length && trimArray(data, 3);

  console.log("TopOffers received id:", id);

  return (
    !searchTerm.length && (
      <div className="w-full rounded-[16px] mt-[24px] py-[20px] phone:p-[16px] tablet:pt-[20px] tablet:pb-[40px] px-[24px] desktop:bg-[#FBD78B] tablet:bg-none phone:bg-[#FBD78B]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-[12px]">
            <img
              src={fireIcon}
              className="w-[28px] h-[32px] phone:w-[21px] phone:h-[24px]"
              alt="fire"
            />
            <h3 className="font-bold text-neutral-900 phone:leading-[27px] text-[20px] tablet:text-[24px] desktop:text-[24px] leading-[33.6px]">
              Top offers
            </h3>
          </div>
          <button
            className="flex items-center gap-[6px]"
            jf-ext-button-ct="view all"
          >
            <p className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] font-medium text-primary-main ">
              View all
            </p>
            <img
              src={chevronIcon}
              className="w-[16px] h-[16px]"
              alt="chevron"
            />
          </button>
        </div>
        {/* Cards */}
        <div className="w-full grid grid-cols-3 phone:grid-cols-2 phone:gap-[8px] tablet:grid-cols-2 gap-[16px] mt-[20px]">
          {isLoading
            ? [...Array(3)].map((_, index) => <LoadingCard key={index} />)
            : deals && deals.length
            ? deals.map((deal, index) => (
                <Card
                  key={index}
                  image={getProductingleImage(deal.fldDealImage1)}
                  inVat={getLowestPriceObject(deal.prices).fldIncVAT}
                  title={deal.fldDealTitle || "title na"}
                  price={getLowestPriceObject(deal.prices).fldPrice || "NA"}
                  markup={deal.fldDealMarkup || "markup na"}
                  rrp={deal.fldDealRRP || "deal.rrp"}
                  grossProfit={calculateProfitAndOrderUnits(deal)}
                  featuredDate={
                    formatDate(deal.fldDealCreationDate) || "deal.featuredOn"
                  }
                  likeButtonShow={true}
                  like={deal.favorite}
                  units={deal.fldMinimumOrder}
                  fldDealCurrencyId={deal.fldDealCurrencyId}
                  prices={deal.prices}
                  comparison={
                    deal.samples && deal.samples.length && deal.samples
                  }
                  slug={deal.fldDealSlug}
                  id={deal.fldDealId}
                />
              ))
            : ""}
        </div>
      </div>
    )
  );
};

export default TopOffers;
