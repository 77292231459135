import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ELASTICSEARCH} from "../api";
import logout from "../../_helperfunctions/Logout";
export const getResponse = createAsyncThunk(
  "/getsearchlist",
  async (data,{ rejectWithValue }) => {
    try {
    const response = await axios.get(`${ELASTICSEARCH}?query=${data.value}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
    return response.data;
  }
  catch (error) {
    // Return a custom error message using rejectWithValue
    return rejectWithValue(
      error.response?.data
    );
  }
}
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const elasticSearchSlice = createSlice({
  name: "elastic",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getResponse.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(getResponse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResponse.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload && action.payload.error;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});
export default elasticSearchSlice.reducer;
