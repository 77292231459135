import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../hooks/cookies"; // Ensure this is correct
import { CHANGE_ORDER_STATUS } from '../api';  // Ensure this URL is correct

// Thunk for canceling an order
export const cancelOrder = createAsyncThunk(
  "cancelOrder/cancelOrder",
  async ({ orderId, userType }, { rejectWithValue }) => {
    try {
      const token = getToken(); // Ensure this is correct
      const status = "cancelled"; // Set the status to "cancelled" when the order is canceled

      console.log("Cancelling order with ID:", orderId);  // Log the orderId for debugging

      // Build the dynamic API endpoint URL
      const apiUrl = `${CHANGE_ORDER_STATUS}/${orderId}`;

      // Ensure you only allow cancellation for the correct user type (seller or buyer)
      if ((userType === "seller" || userType === "buyer") && orderId) {
        const response = await axios.post(
          apiUrl,  // Use the dynamic URL with orderId
          { status: status }, // Send the status as part of the body
          {
            headers: { Authorization: `Bearer ${token}` },  // Ensure token is correctly set
          }
        );
        console.log("API Response:", response.data);  // Log the API response for debugging
        return response.data; // Return the API response
      } else {
        return rejectWithValue("You can only cancel your own orders.");
      }
    } catch (error) {
      console.error("Error Details:", error.response?.data || error.message); // Log detailed error
      return rejectWithValue(error.response?.data || "Failed to cancel the order");
    }
  }
);

const cancelOrderSlice = createSlice({
  name: "cancelOrder",
  initialState: {
    success: false,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cancelOrder.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(cancelOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(cancelOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to cancel the order";
      });
  },
});

export default cancelOrderSlice.reducer;
